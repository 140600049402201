import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/Avatar';
import styles from './Card.module.scss';


const Card = ({
  avatar,
  title,
  subtitle,
  image,
  children,
  actions,
  onClick,
  className,
}) => {
  const wrapperClasses = [
    styles.wrapper,
    className,
  ].join(' ');

  return (
    <div
      className={wrapperClasses}
      onClick={onClick}
      onKeyUp={e => ((e.keyCode === 13 && onClick) ? onClick() : null)}
      role="button"
      tabIndex={onClick ? 0 : null}
    >
      {title && (
        <div className={styles.meta}>
          {avatar && <Avatar className={styles.avatar} src={avatar} alt={title} />}
          <div className={styles.titles}>
            <div className={[styles.title, !subtitle && styles.titleLarge].join(' ')}>{title}</div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
          </div>
        </div>
      )}
      <div
        className={styles.content}
        role="button"
        tabIndex={onClick ? 0 : null}
      >
        {image && <img className={styles.image} src={image} alt={title} />}
        <div className={styles.body}>{children}</div>
      </div>
      <div className={styles.actions}>{actions}</div>
    </div>
  );
};

Card.propTypes = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  actions: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  avatar: null,
  title: null,
  subtitle: null,
  image: null,
  actions: null,
};

export default Card;
