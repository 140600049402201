// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-UITitle-module-title-JL5E9{padding:0px 16px;font-size:1.175rem;text-transform:capitalize;color:#37474f}`, "",{"version":3,"sources":["webpack://./src/components/UITitle/UITitle.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAIA,6BACE,gBAAA,CACA,kBAAA,CACA,yBAAA,CACA,aCcS","sourcesContent":["@import \"../../theme/colors\";\n@import \"../../theme/dimensions\";\n\n\n.title {\n  padding: 0px $gutter;\n  font-size: 1.175rem;\n  text-transform: capitalize;\n  color: $grey-800;\n}\n","$yellow: #fcdb00;\n$yellow-main: #ffd700;\n$yellow-dark: #f9ad00;\n$yellow-message: #FFD900;\n$yellow-reply: #FFF099;\n$red: #f1321e;\n$green-400: #66bb6a;\n$green: #00ff00;\n$orange-500: #ff9800;\n$black: #000000;\n$white-800: #fefefe;\n$white: #ffffff;\n$invictus-white: #fff;\n\n$grey-50: #eceff1;\n$grey-100: #cfd8dc;\n$grey-200: #b0bec5;\n$grey-300: #90a4ae;\n$grey-400: #78909c;\n$grey-500: #607d8b;\n$grey-600: #546e7a;\n$grey-700: #455a64;\n$grey-800: #37474f;\n$grey-900: #263238;\n$grey-reply: #CCCCCC;\n$grey-message: #999999;\n$invictus-2nd-dark-grey: #666;\n$invictus-3rd-dark-grey: #333;\n\n$primary: $yellow;\n$secondary: $grey-600;\n$success: $green-400;\n$warning: $orange-500;\n$danger: $red;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `-UITitle-module-title-JL5E9`
};
export default ___CSS_LOADER_EXPORT___;
