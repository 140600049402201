var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { FEATURE_FLAGS } from '../../util/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { MainContext } from '../../context/MainContext';
import styles from './TabNav.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
var mapStateToProps = function (_a) {
    var notifications = _a.notifications;
    return ({
        notificationCount: notifications.history.unreadCount,
    });
};
var TabNav = function () {
    var t = useTranslation().t;
    var notificationCount = useSelector(mapStateToProps).notificationCount;
    var boards = useContext(MainContext).boards;
    var navigationItems = [
        {
            to: '/all-posts',
            label: t('posts.label'),
            icon: _jsx(FontAwesomeIcon, { icon: ['far', 'comments'] }),
        },
        {
            to: '/settings',
            label: t('settings.label'),
            icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'sliders-h'] }),
        },
    ];
    if (FEATURE_FLAGS.savedPosts) {
        navigationItems.splice(navigationItems.length - 1, 0, {
            to: '/saved',
            label: t('saved.label'),
            icon: _jsx(FontAwesomeIcon, { icon: ['far', 'bookmark'] }),
        });
    }
    if (FEATURE_FLAGS.notifications) {
        navigationItems.splice(navigationItems.length - 1, 0, {
            to: '/notifications',
            label: t('notifications.label'),
            icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'bell'] }),
            badgeCount: notificationCount,
        });
    }
    if (FEATURE_FLAGS.chat) {
        navigationItems.splice(navigationItems.length - 1, 0, {
            to: '/chat',
            label: t('chat.label'),
            icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'message'] }),
        });
    }
    return (_jsx("div", __assign({ className: styles.wrapper }, { children: _jsx("ul", __assign({ className: styles.menu }, { children: navigationItems.map(function (_a) {
                var to = _a.to, label = _a.label, icon = _a.icon, badgeCount = _a.badgeCount;
                return (_jsx("li", { children: _jsxs(Link, __assign({ to: to, activeClassName: styles.active }, { children: [badgeCount && badgeCount > 0 ? (_jsx("div", __assign({ className: styles.badge }, { children: badgeCount }))) : null, _jsx(ButtonIcon, { to: to, title: label, icon: icon }), _jsx("div", __assign({ className: styles.label }, { children: label }))] })) }, label));
            }) })) })));
};
export default TabNav;
