var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { updateLanguagesDispatch, updateLanguagesFulfill, updateLanguagesReject, } from '../../redux/modules/autoTranslate';
import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import { Preferences } from '@capacitor/preferences';
import Toggle from '../Toggle/Toggle';
import api from '../../util/api';
import s from './AutoTranslateDisclaimer.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The AutoTranslater Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
var AutoTranslateDisclaimer = function () {
    var dispatch = useDispatch();
    var _a = useState(false), showDisclaimer = _a[0], setShowDisclaimer = _a[1];
    var t = useTranslation().t;
    useEffect(function () {
        //check if autoTranslateDisclaimerShown is set
        void Preferences.get({
            key: 'autoTranslateDisclaimerShown',
        }).then(function (_a) {
            var value = _a.value;
            if (value === null) {
                setShowDisclaimer(true);
            }
            else if (value !== 'true') {
                setShowDisclaimer(false);
                return;
            }
        });
        void Preferences.get({
            key: 'autoTranslateStatus',
        }).then(function (_a) {
            var value = _a.value;
            if (!value) {
                void Preferences.set({
                    key: 'autoTranslateStatus',
                    value: 'true',
                });
            }
        });
    }, []);
    var _b = useState(false), isChanging = _b[0], setIsChanging = _b[1];
    var _c = useState(true), autoTranslateStatus = _c[0], setAutoTranslateStatus = _c[1];
    var toggleAutoTranslate = function () {
        var autoTranslatePreference = autoTranslateStatus ? 'false' : 'true';
        void Preferences.set({
            key: 'autoTranslateStatus',
            value: autoTranslatePreference,
        }).then(function () {
            var values = {
                language: void Preferences.get({
                    key: 'autoTranslateStatus',
                }) || 'en',
                autoTranslatePreference: autoTranslateStatus,
            };
            dispatch(updateLanguagesDispatch(values));
            api
                .autoTranslate(values)
                .then(function (response) {
                dispatch(updateLanguagesFulfill(response));
            })
                .catch(function (error) {
                dispatch(updateLanguagesReject(error.message));
            });
            setIsChanging(true);
            setAutoTranslateStatus(!autoTranslateStatus);
            setIsChanging(false);
        });
    };
    return (_jsx(Modal, __assign({ show: showDisclaimer }, { children: _jsx(Card, __assign({ title: '', className: s.wrapper }, { children: _jsxs("div", __assign({ style: { height: '50px' } }, { children: [_jsx("div", { children: _jsx("h2", __assign({ style: { lineHeight: 0 } }, { children: t('autoTranslate.label') })) }), _jsx("div", __assign({ style: { float: 'right', marginTop: '.5rem' } }, { children: _jsx(Toggle, { id: '1', checked: autoTranslateStatus, pending: isChanging, onChange: toggleAutoTranslate, className: undefined, onClick: undefined }) })), _jsx("div", { style: { marginTop: '.5rem' } }), _jsx("p", __assign({ className: s.textbody }, { children: t('autoTranslatePrompt.label') })), _jsx(ButtonGroup, { children: _jsx(Button, { label: t('save.label'), onClick: function () {
                                return void Preferences.set({
                                    key: 'autoTranslateDisclaimerShown',
                                    value: '1',
                                }).then(function () {
                                    setShowDisclaimer(false);
                                    var autoTranslatePreference = autoTranslateStatus
                                        ? true
                                        : false;
                                    var values = {
                                        language: void Preferences.get({
                                            key: 'language',
                                        }) || 'en',
                                        autoTranslatePreference: autoTranslatePreference,
                                    };
                                    dispatch(updateLanguagesDispatch(values));
                                    api
                                        .autoTranslate(values)
                                        .then(function (response) {
                                        dispatch(updateLanguagesFulfill(response));
                                    })
                                        .catch(function (error) {
                                        dispatch(updateLanguagesReject(error.message));
                                    });
                                    setIsChanging(true);
                                    setAutoTranslateStatus(!autoTranslateStatus);
                                    setIsChanging(false);
                                });
                            }, fullWidth: true }) }), _jsx("div", { style: { float: 'right', marginTop: '.5rem' } })] })) })) })));
};
export default AutoTranslateDisclaimer;
