import React from 'react';
import PropTypes from 'prop-types';
import TruncatedText from '../TruncatedText/TruncatedText';
import styles from './FieldLabel.module.scss';


const FieldLabel = (props) => {
  const {
    label,
    required,
    disabled,
    light,
  } = props;
  const wrapperClasses = [
    styles.wrapper,
    disabled && styles.disabled,
    light && styles.light,
  ].join(' ');

  return (
    <div className={wrapperClasses}>
      <TruncatedText text={required ? `${label}*` : label} />
    </div>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
};

FieldLabel.defaultProps = {
  required: false,
  disabled: false,
  light: false,
};

export default FieldLabel;
