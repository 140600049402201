var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, } from 'react';
import ActionSheet from '../ActionSheet/ActionSheet';
// import { AuthContext } from '../../context/AuthProvider';
import ChatMessage from './ChatMessage';
import { FEATURE_FLAGS } from '../../util/config';
import FlagMessageForm from '../../forms/FlagMessageForm/FlagMessageForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HideShow from '../HideShow/HideShow';
import RemoveMessageListItem from '../RemoveMessageListItem/RemoveMessageListItem';
import UIBlockListItem from '../UIBlockListItem/UIBlockListItem';
import UIListItem from '../UIListItem/UIListItem';
import moment from 'moment';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';
import { useMessageHide } from '../../hooks/messageHooks';
import { useTranslation } from 'react-i18next';
import { useAuth, useProfile } from '../../util/APIDjango';
/**
 * Handles rendering the action sheet for a Chat Message
 * @param {ActionableChatMessageProps} props
 * @returns {JSX.Element}
 */
var ActionableChatMessage = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var chatId = _a.chatId, message = _a.message, isBlocked = _a.isBlocked, // deleteMessage,
    quickViewMessageId = _a.quickViewMessageId, setQuickViewMessageId = _a.setQuickViewMessageId, setReplyMessage = _a.setReplyMessage, likedByMe = _a.likedByMe, setShouldScrollToBottom = _a.setShouldScrollToBottom, callParentFunction = _a.callParentFunction;
    var t = useTranslation().t;
    // const { uid, user }: { uid: number; user: Profile } = useContext(AuthContext);
    var _h = useAuth(), authState = _h[0], setAuthState = _h[1];
    var _j = useProfile(), profileState = _j[0], setProfileState = _j[1];
    var uid = authState.uid;
    var user = profileState.profile;
    var _k = useState(message === null || message === void 0 ? void 0 : message.content), originalContent = _k[0], setOriginalContent = _k[1];
    var _l = useGoogleTranslate(originalContent), translate = _l.translate, translated = _l.translated, translation = _l.translation, translationError = _l.translationError;
    var _m = useState(message), translatableMessage = _m[0], setTranslatableMessage = _m[1];
    var _o = useState(null), activeActionSheet = _o[0], setActiveActionSheet = _o[1];
    var _p = useState(null), activeFlagForm = _p[0], setActiveFlagForm = _p[1];
    var _q = useMessageHide(chatId, message === null || message === void 0 ? void 0 : message.id), hideMessage = _q.hideMessage, getOriginalContent = _q.getOriginalContent;
    // used to stop actionsheet updating on block.
    var _r = useState(null), delayedIsBlocked = _r[0], setDelayedIsBlocked = _r[1];
    var _s = useState(false), usingTranslation = _s[0], setUsingTranslation = _s[1];
    var toggleActionSheet = function (id) {
        if (id === null) {
            setActiveFlagForm(null);
            setDelayedIsBlocked(isBlocked);
        }
        setActiveActionSheet(id);
    };
    useEffect(function () {
        var _a;
        if (!(message === null || message === void 0 ? void 0 : message.translatedContent) &&
            (message === null || message === void 0 ? void 0 : message.content) &&
            ((_a = message === null || message === void 0 ? void 0 : message.author) === null || _a === void 0 ? void 0 : _a.id) !== (user === null || user === void 0 ? void 0 : user.id) &&
            moment(message === null || message === void 0 ? void 0 : message.lastModified).isAfter(moment().subtract(1, 'minutes'))) {
            handleTranslate();
        }
    }, [message === null || message === void 0 ? void 0 : message.content]);
    var handleTranslate = function () {
        if (!translated) {
            translate();
            setUsingTranslation(true);
        }
        else if (usingTranslation) {
            setTranslatableMessage(message);
            setUsingTranslation(false);
        }
        else {
            setUsingTranslation(true);
            setTranslatableMessage(__assign(__assign({}, message), { content: translation }));
        }
        toggleActionSheet(null);
    };
    var handleOriginalContent = function () { return __awaiter(void 0, void 0, void 0, function () {
        var msg;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getOriginalContent()];
                case 1:
                    msg = _a.sent();
                    setTranslatableMessage(function (prev) {
                        return !(translatableMessage === null || translatableMessage === void 0 ? void 0 : translatableMessage.content)
                            ? __assign(__assign({}, prev), { content: msg === null || msg === void 0 ? void 0 : msg.content }) : message;
                    });
                    setOriginalContent(msg === null || msg === void 0 ? void 0 : msg.content);
                    toggleActionSheet(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleHideMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, hideMessage(!(message === null || message === void 0 ? void 0 : message.isHidden))];
                case 1:
                    _a.sent();
                    setOriginalContent(message === null || message === void 0 ? void 0 : message.content);
                    setTranslatableMessage(__assign({}, message));
                    setUsingTranslation(false);
                    toggleActionSheet(null);
                    setQuickViewMessageId(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleQuickView = function (messageId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, handleOriginalContent()];
                case 1:
                    _a.sent();
                    setQuickViewMessageId(messageId === quickViewMessageId ? null : messageId);
                    toggleActionSheet(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleReplyMessage = function (replyMessage) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setReplyMessage(replyMessage);
            toggleActionSheet(null);
            return [2 /*return*/];
        });
    }); };
    useEffect(function () { return setDelayedIsBlocked(isBlocked); }, [isBlocked]);
    useEffect(function () { return setOriginalContent((message === null || message === void 0 ? void 0 : message.content) || ''); }, [message]);
    useEffect(function () {
        if (translation) {
            setTranslatableMessage(__assign(__assign({}, message), { content: translation }));
        }
    }, [translation, message]);
    useEffect(function () {
        if (originalContent !== (message === null || message === void 0 ? void 0 : message.content)) {
            setTranslatableMessage(__assign(__assign({}, message), { content: (message === null || message === void 0 ? void 0 : message.content) || originalContent }));
        }
    }, [originalContent, message]);
    var isQuickViewMessage = quickViewMessageId === (message === null || message === void 0 ? void 0 : message.id);
    var isModerator = (_b = user === null || user === void 0 ? void 0 : user.permissions) === null || _b === void 0 ? void 0 : _b.includes('chat.hide_message');
    return (((_c = message === null || message === void 0 ? void 0 : message.author) === null || _c === void 0 ? void 0 : _c.id) && (message === null || message === void 0 ? void 0 : message.likesCount) !== undefined && (_jsxs(_Fragment, { children: [_jsx(ChatMessage, { likedByMe: likedByMe, chatId: chatId, translatedMessage: usingTranslation ? t('translationByGoogle.label') : null, translationError: translationError, isHidden: message === null || message === void 0 ? void 0 : message.isHidden, isBlocked: isBlocked, displayQuickView: isQuickViewMessage &&
                    ((_d = user === null || user === void 0 ? void 0 : user.permissions) === null || _d === void 0 ? void 0 : _d.includes('chat.hide_message')), message: translatableMessage, onClick: function () { return toggleActionSheet(message === null || message === void 0 ? void 0 : message.id); }, originalContent: originalContent, setShouldScrollToBottom: setShouldScrollToBottom }), _jsxs(ActionSheet, __assign({ show: activeActionSheet === (message === null || message === void 0 ? void 0 : message.id), backDropClick: function () { return toggleActionSheet(null); } }, { children: [!message.isHidden && (_jsx(UIListItem, __assign({ onClick: function () { return void handleReplyMessage(message); }, icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'reply'] }) }, { children: t('reply.label') }))), delayedIsBlocked ? (_jsxs(_Fragment, { children: [_jsx(UIListItem, __assign({ icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'exclamation-circle'] }) }, { children: "Unblock user?" })), _jsx(UIBlockListItem, { id: (_e = message === null || message === void 0 ? void 0 : message.author) === null || _e === void 0 ? void 0 : _e.username, author: message === null || message === void 0 ? void 0 : message.author })] })) : (_jsx(_Fragment, { children: ((_f = message === null || message === void 0 ? void 0 : message.author) === null || _f === void 0 ? void 0 : _f.id) === uid ? (_jsx(RemoveMessageListItem, { chatId: chatId, messageId: message === null || message === void 0 ? void 0 : message.id, toggleActionSheet: toggleActionSheet })) : (_jsxs(_Fragment, { children: [FEATURE_FLAGS.googleTranslate &&
                                    (!message.isHidden ||
                                        (isQuickViewMessage && isModerator)) && (_jsx(UIListItem, __assign({ onClick: handleTranslate, icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'language'] }) }, { children: usingTranslation
                                        ? t('removeTranslation.label')
                                        : t('translateMessage.label') }))), isModerator && (_jsx(UIListItem, __assign({ onClick: function () { return void handleHideMessage(); }, icon: _jsx(FontAwesomeIcon, { icon: [
                                            'fas',
                                            (message === null || message === void 0 ? void 0 : message.isHidden) ? 'eye' : 'eye-slash',
                                        ] }) }, { children: (message === null || message === void 0 ? void 0 : message.isHidden)
                                        ? t('showMessageAllUsers.label')
                                        : t('hideMessageAllUsers.label') }))), isModerator && (message === null || message === void 0 ? void 0 : message.isHidden) && (_jsx(UIListItem, __assign({ onClick: function () { return void handleQuickView(message === null || message === void 0 ? void 0 : message.id); }, icon: _jsx(FontAwesomeIcon, { icon: [
                                            'fas',
                                            isQuickViewMessage ? 'eye-slash' : 'eye',
                                        ] }) }, { children: !isQuickViewMessage
                                        ? t('quickViewMessage.label')
                                        : t('quickViewMessage.label') }))), _jsx(UIListItem, __assign({ onClick: function () { return setActiveFlagForm(message === null || message === void 0 ? void 0 : message.id); }, icon: _jsx(FontAwesomeIcon, { icon: ['fas', 'exclamation-circle'] }) }, { children: t('flagBlock.label') })), _jsx(HideShow, __assign({ show: activeFlagForm === (message === null || message === void 0 ? void 0 : message.id) }, { children: _jsx(FlagMessageForm, { author: __assign(__assign({}, message === null || message === void 0 ? void 0 : message.author), { avatar: __assign(__assign({}, (_g = message === null || message === void 0 ? void 0 : message.author) === null || _g === void 0 ? void 0 : _g.avatar), { alt: 'User Avatar' }) }), id: message === null || message === void 0 ? void 0 : message.id, chatId: chatId, handleCancel: function () { return toggleActionSheet(null); } }) }))] })) }))] }))] })));
};
export default ActionableChatMessage;
