// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-ChatDisclaimer-module-label-hHtoR{font-family:"nexa";font-weight:400}.-ChatDisclaimer-module-bodyText-KUL9n{font-family:"din";font-weight:200}`, "",{"version":3,"sources":["webpack://./src/components/ChatDisclaimer/ChatDisclaimer.module.scss"],"names":[],"mappings":"AAAA,oCACE,kBAAA,CACA,eAAA,CAGF,uCACE,iBAAA,CACA,eAAA","sourcesContent":[".label {\n  font-family: \"nexa\";\n  font-weight: 400;\n}\n\n.bodyText {\n  font-family: \"din\";\n  font-weight: 200;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `-ChatDisclaimer-module-label-hHtoR`,
	"bodyText": `-ChatDisclaimer-module-bodyText-KUL9n`
};
export default ___CSS_LOADER_EXPORT___;
