var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../../components/ButtonIcon/ButtonIcon';
import styles from './CommentForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MentionsEditor, { editorStateToString, } from '../../components/MentionsEditor/MentionsEditor';
import { EditorState } from 'draft-js';
import api from '../../util/api';
import { usePosts } from '../../util/APIDjango';
import { MainContext } from '../../context/MainContext';
var CommentForm = function (_a) {
    var postId = _a.postId, boardId = _a.boardId;
    var t = useTranslation().t;
    var dispatch = useDispatch();
    var _b = useState(false), isSubmitting = _b[0], setSubmitting = _b[1];
    var _c = useState(EditorState.createEmpty()), editorState = _c[0], setEditorState = _c[1];
    var _d = usePosts(), post = _d[0], setPost = _d[1];
    var fetchCommentsForPost = useContext(MainContext).fetchCommentsForPost;
    var handleSubmit = function (e) {
        e.preventDefault();
        setSubmitting(true);
        var content = editorStateToString(editorState);
        // dispatch(commentCreateDispatch(postId));
        postSubmitDispatch(postId);
        api
            .createComment({ postId: postId, content: content })
            .then(function (comment) {
            // dispatch(commentCreateFulfill(postId, comment));
            postSubmitFulfill(postId);
            fetchCommentsForPost(postId);
            setSubmitting(false);
            setEditorState(EditorState.createEmpty());
        })
            .catch(function (err) {
            // dispatch(commentCreateReject(err));
            postSubmitError(err);
            setSubmitting(false);
        });
    };
    var postSubmitDispatch = function (postID) {
        setPost(__assign(__assign({}, post), { isPosting: true, isPosted: false, error: null }));
    };
    var postSubmitFulfill = function (postID) {
        setPost(__assign(__assign({}, post), { isPosting: false, isPosted: true, error: null }));
    };
    var postSubmitError = function (error) {
        setPost(__assign(__assign({}, post), { isPosting: false, isPosted: false, error: error }));
    };
    return (_jsx("form", __assign({ className: styles.form, onSubmit: handleSubmit }, { children: _jsxs("div", __assign({ className: styles.container }, { children: [_jsx(MentionsEditor, { id: "comment-input", placeholder: t('addComment.label'), disabled: isSubmitting, boardId: String(boardId), editorState: editorState, setEditorState: setEditorState }), _jsx(ButtonIcon, { className: styles.submit, title: "Comment", type: "submit", icon: _jsx(FontAwesomeIcon, { icon: ['far', 'paper-plane'] }) })] })) })));
};
export default CommentForm;
