var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import ActionableChatMessage from '../ChatMessage/ActionableChatMessage';
import ChatForm from '../../forms/ChatForm/ChatForm';
import Loader from '../Loader/Loader';
import s from './ChatWindow.module.scss';
// import { useChatMessages } from '../../hooks/useChatMessages';
import { useParams } from 'react-router';
import { ChatWsEvents } from '../../types/ChatMessage';
import api from '../../util/api';
import { useMessages, useProfile } from '../../util/APIDjango';
import { addMessagesToList } from '../../redux/modules/chat';
import useWebSocket from 'react-use-websocket';
import { WS_API_DOMAIN } from '../../util/config';
// const mapStateToProps = ({ blocked }: RootState) => ({
//   blockedUsers: blocked.users,
// });
var ChatWindow = function (_a) {
    var _b;
    var chatId = _a.chatId;
    sessionStorage.setItem('reloadNotification', 'Y');
    var messageId = useParams().messageId;
    var LIMIT = 20;
    var AROUND = messageId;
    var _c = useMessages(), messagesData = _c[0], setMessagesData = _c[1];
    var _d = useState(), socketError = _d[0], setSocketError = _d[1];
    var _e = useState(false), wsAuthenticated = _e[0], setWsAuthenticated = _e[1];
    var _f = useState(((_b = messagesData === null || messagesData === void 0 ? void 0 : messagesData.messagesByRoom) === null || _b === void 0 ? void 0 : _b.results) || []), onlyMessages = _f[0], setOnlyMessages = _f[1];
    var _g = useState(false), fetchingNext = _g[0], setFetchingNext = _g[1];
    var _h = useWebSocket("".concat(WS_API_DOMAIN, "/chat-rooms/").concat(chatId, "/websocket/"), {
        shouldReconnect: function (closeEvent) { return false; },
        onOpen: function () { },
        reconnectInterval: 50000,
        onClose: function (event) {
            switch (event.code) {
                // case 4002: // user banned
                // case 4003: // chat room deleted
                // case 4000: // user not authenticated
                // case 1006: // connection closed unexpectedly
                default:
                    // history.push('/chat/');
                    console.log('WebSocket closed:', event.code, event.reason);
                    break;
            }
            // dispatch(getChatRoomsDispatch());
        },
        onError: function (error) {
            console.log('WebSocket error:', error);
        },
    }), sendJsonMessage = _h.sendJsonMessage, lastJsonMessage = _h.lastJsonMessage;
    useEffect(function () {
        if (lastJsonMessage) {
            switch (lastJsonMessage.type) {
                case 'message.saved':
                    // setMessagesData(prevMessagesData => {
                    //   const updatedResults = prevMessagesData.messagesByRoom.results.map(
                    //     message =>
                    //       message.id === lastJsonMessage.message.id
                    //         ? lastJsonMessage.message
                    //         : message,
                    //   );
                    //   // Checking if the message was not found and add it to the results
                    //   const messageExists = updatedResults.some(
                    //     message => message.id === lastJsonMessage.message.id,
                    //   );
                    //   if (!messageExists) {
                    //     updatedResults.push(lastJsonMessage.message);
                    //   }
                    //   return {
                    //     ...prevMessagesData,
                    //     messagesByRoom: {
                    //       ...prevMessagesData.messagesByRoom,
                    //       results: updatedResults,
                    //     },
                    //     lastMessageIdFetched: lastJsonMessage.id,
                    //   };
                    // });
                    setMessagesData(function (prevMessagesData) {
                        var newMessage = __assign(__assign({}, lastJsonMessage.message), { created: new Date(lastJsonMessage.message.created), lastModified: new Date(lastJsonMessage.message.lastModified) });
                        return __assign(__assign({}, prevMessagesData), { messagesByRoom: __assign(__assign({}, prevMessagesData.messagesByRoom), { results: addMessagesToList(prevMessagesData.messagesByRoom.results, [newMessage]) }), lastMessageIdFetched: lastJsonMessage.id });
                    });
                    setTimeout(function () {
                        setShouldScrollAfterReceive(true);
                    }, 500);
                    break;
                case 'message.likes_count.saved':
                    setMessagesData(function (prevMessagesData) { return (__assign(__assign({}, prevMessagesData), { messagesByRoom: __assign(__assign({}, prevMessagesData.messagesByRoom), { results: prevMessagesData.messagesByRoom.results.map(function (message) {
                                return message.id === lastJsonMessage.messageId
                                    ? __assign(__assign({}, message), { likesCount: lastJsonMessage.likesCount }) : message;
                            }) }) })); });
                    break;
                default:
                    break;
            }
        }
    }, [lastJsonMessage]);
    useEffect(function () {
        !wsAuthenticated &&
            api
                .getToken()
                .then(function (token) {
                return sendJsonMessage({ type: ChatWsEvents.AUTHENTICATION, token: token });
            })
                .catch(function (e) { return setSocketError(e.message); });
    }, [sendJsonMessage, wsAuthenticated]);
    var getChatMessages = function () {
        return setMessagesData(__assign(__assign({}, messagesData), { messagesFetched: false, messagesFetching: true, isFetching: true }));
    };
    var getChatMessagesFulfil = function (data, isPrevious) {
        var _a;
        var updatedMessagesByRoom;
        if (isPrevious) {
            var likedByMeCombined = __spreadArray(__spreadArray([], (messagesData.messagesByRoom.likedByMe || []), true), (data.likedByMe || []), true);
            likedByMeCombined = Array.from(new Set(likedByMeCombined));
            updatedMessagesByRoom = __assign(__assign({}, messagesData.messagesByRoom), { results: addMessagesToList(messagesData.messagesByRoom.results || [], (data === null || data === void 0 ? void 0 : data.results) || []), likedByMe: likedByMeCombined, next: data === null || data === void 0 ? void 0 : data.next, previous: data === null || data === void 0 ? void 0 : data.previous });
        }
        else {
            updatedMessagesByRoom = __assign(__assign({}, messagesData.messagesByRoom), { results: addMessagesToList(((_a = messagesData.messagesByRoom) === null || _a === void 0 ? void 0 : _a.results) || [], (data === null || data === void 0 ? void 0 : data.results) || []), likedByMe: data.likedByMe, next: data.next, previous: data.previous });
        }
        setMessagesData(__assign(__assign({}, messagesData), { lastMessageIdFetched: data.results[data.results.length - 1].id, isFetched: true, isFetching: false, messagesByRoom: updatedMessagesByRoom }));
    };
    var getChatMessagesReject = function (error) {
        return setMessagesData(__assign(__assign({}, messagesData), { fetchError: error, messagesFetched: true, messagesFetching: false }));
    };
    var chatMessages = function () {
        getChatMessages();
        api
            .chatMessages(chatId, { limit: LIMIT, around: AROUND })
            .then(function (messageData) {
            getChatMessagesFulfil(messageData, false);
            setTimeout(function () {
                handleScrollToMessage();
            }, 100);
        })
            .catch(function (err) {
            getChatMessagesReject(err.message);
        });
    };
    useEffect(function () {
        chatMessages();
    }, []);
    var fetchPrevious = function () {
        if (messagesData.messagesByRoom.previous) {
            getChatMessages();
            api
                .chatMessages(chatId, {
                // around: messagesData.messagesByRoom.results[0].id,
                url: messagesData.messagesByRoom.previous
            })
                .then(function (messageData) {
                getChatMessagesFulfil(messageData, true);
            })
                .catch(function (err) {
                getChatMessagesReject(err.message);
            });
        }
    };
    var fetchNext = function () {
        setFetchingNext(true);
        if (messagesData.messagesByRoom.next) {
            getChatMessages();
            api
                .chatMessages(chatId, {
                // around: messagesData.messagesByRoom.results[0].id,
                url: messagesData.messagesByRoom.next
            })
                .then(function (messageData) {
                getChatMessagesFulfil(messageData, true);
                setFetchingNext(false);
            })
                .catch(function (err) {
                getChatMessagesReject(err.message);
                setFetchingNext(false);
            });
        }
    };
    var _j = useState(null), replyMessage = _j[0], setReplyMessage = _j[1];
    var scrollContainerRef = useRef(null);
    // const { blockedUsers } = useSelector(mapStateToProps);
    var _k = useProfile(), profileData = _k[0], setProfileData = _k[1];
    var getProfileData = function () {
        return setProfileData(__assign(__assign({}, profileData), { isFetching: true }));
    };
    var profileDataFulfill = function (data) {
        return setProfileData(__assign(__assign({}, data), { profile: data, isFetching: false, isFetched: true, error: null }));
    };
    var profileDataReject = function (error) {
        return setProfileData(__assign(__assign({}, profileData), { error: error, isFetching: false }));
    };
    useEffect(function () {
        getProfileData();
        api
            .profileGet()
            .then(function (profileData) {
            profileDataFulfill(profileData);
        })
            .catch(function (err) {
            profileDataReject(err.message);
        });
    }, []);
    var _l = useState(null), quickViewMessageId = _l[0], setQuickViewMessageId = _l[1];
    var _m = useState(true), shouldScrollToBottom = _m[0], setShouldScrollToBottom = _m[1];
    var _o = useState(false), shouldScrollAfterSend = _o[0], setShouldScrollAfterSend = _o[1];
    var _p = useState(false), shouldScrollAfterReceive = _p[0], setShouldScrollAfterReceive = _p[1];
    // useEffect(() => {
    //   if (messagesData.isFetching) return;
    //   if (messageId) {
    //     handleScrollToMessage();
    //   } else if (shouldScrollToBottom) {
    //     scrollContainerRef.current?.scrollTo(0, scrollContainerRef.current.scrollHeight);
    //   }
    // }, [messagesData.messagesByRoom.results, messageId, shouldScrollToBottom]);
    useEffect(function () {
        var scrollWindow = document.getElementById('scrollWindow');
        var handleScroll = function () {
            if (scrollWindow.scrollHeight - scrollWindow.scrollTop <= scrollWindow.clientHeight + 1) {
                fetchNext();
                setShouldScrollToBottom(true);
            }
            else if (scrollWindow.scrollTop === 0) {
                fetchPrevious();
                setShouldScrollToBottom(false);
            }
        };
        scrollWindow.addEventListener('scroll', handleScroll);
        return function () {
            scrollWindow.removeEventListener('scroll', handleScroll);
        };
    }, [messagesData]);
    var handleScrollToMessage = function () {
        var _a;
        console.log("coming here");
        var element = document.getElementById("message_".concat(messageId));
        if (messageId && element) {
            setTimeout(function () {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                setShouldScrollToBottom(false);
            }, 500);
        }
        else {
            (_a = scrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, scrollContainerRef.current.scrollHeight);
            setShouldScrollToBottom(true);
        }
    };
    var updateMessageView = function (data) {
        var index = messagesData.messagesByRoom.results.findIndex(function (msg) { return msg.id === data.id; });
        var updatedResults = __spreadArray([], messagesData.messagesByRoom.results, true);
        updatedResults[index] = __assign(__assign({}, updatedResults[index]), { isHidden: !data.isHidden });
        setMessagesData(__assign(__assign({}, messagesData), { messagesByRoom: __assign(__assign({}, messagesData.messagesByRoom), { results: updatedResults }) }));
        // setOnlyMessages(updatedResults); // Update onlyMessages state here
        // setShouldScrollToBottom(false);
    };
    var sendMessage = function (content, replyMessageId, callback) {
        return api
            .createChatMessage(chatId, content, replyMessageId)
            .then(function (res) {
            if (callback) {
                callback();
            }
        })
            .catch(function (error) {
            console.error('Error:', error);
        });
    };
    useEffect(function () {
        if (messageId) {
            handleScrollToMessage();
        }
    }, [messageId]);
    useEffect(function () {
        var _a, _b;
        if (shouldScrollAfterSend) {
            (_a = scrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, scrollContainerRef.current.scrollHeight);
            setShouldScrollAfterSend(false);
        }
        if (shouldScrollAfterReceive) {
            (_b = scrollContainerRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo(0, scrollContainerRef.current.scrollHeight);
            setShouldScrollAfterReceive(false);
        }
    }, [shouldScrollAfterReceive]);
    useEffect(function () {
        setOnlyMessages(messagesData.messagesByRoom.results);
    }, [messagesData]);
    return (messagesData &&
        onlyMessages && (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: s.wrapper }, { children: [messagesData.fetchError && _jsx("p", { children: messagesData.fetchError }), _jsxs("div", __assign({ id: 'scrollWindow', ref: scrollContainerRef, className: "".concat(s.scrollWindow, " ").concat(replyMessage ? s.withReply : '') }, { children: [messagesData.isFetching && _jsx(Loader, {}), messagesData &&
                            onlyMessages &&
                            (onlyMessages === null || onlyMessages === void 0 ? void 0 : onlyMessages.map(function (msg) {
                                var _a;
                                return (_jsx("div", __assign({ id: "message_".concat(msg.id) }, { children: _jsx(ActionableChatMessage, { message: msg, chatId: chatId, isBlocked: (_a = profileData.blockedUsers) === null || _a === void 0 ? void 0 : _a.some(function (user) { return user.uuid === msg.author.id; }), quickViewMessageId: quickViewMessageId, setQuickViewMessageId: setQuickViewMessageId, setReplyMessage: setReplyMessage, likedByMe: messagesData.messagesByRoom.likedByMe, setShouldScrollToBottom: setShouldScrollToBottom, callParentFunction: updateMessageView }, msg.id) }), msg.id));
                            })), fetchingNext && messagesData.isFetching && _jsx(Loader, {})] })), _jsx(ChatForm, { replyMessage: replyMessage, setReplyMessage: setReplyMessage, callback: function (content) {
                        return sendMessage(content, replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.id).then(function () {
                            setShouldScrollAfterSend(true);
                        });
                    } })] })) })));
};
export default ChatWindow;
