var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var FETCH_NOTIFICATION_HISTORY_DISPATCH = 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_DISPATCHED';
export var FETCH_NOTIFICATION_HISTORY_PAGINATION_DISPATCH = 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_DISPATCHED';
export var FETCH_NOTIFICATION_HISTORY_FULFILL = 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_FULFILLED';
export var FETCH_NOTIFICATION_HISTORY_REJECT = 'WIS/notifications/FETCH_NOTIFICATION_HISTORY_REJECTED';
export var fetchNotificationHistory = function (pageNumber) { return ({
    type: FETCH_NOTIFICATION_HISTORY_DISPATCH,
    pageNumber: pageNumber | 1,
    pageSize: 10,
    nextPage: 2,
    notifications: []
}); };
export var fetchNotificationHistoryPagination = function (pageNumber, pageSize, nextPage, notifications) { return ({
    type: FETCH_NOTIFICATION_HISTORY_PAGINATION_DISPATCH,
    pageNumber: pageNumber,
    pageSize: pageSize,
    nextPage: nextPage,
    notifications: notifications
}); };
export var fetchNotificationHistoryFulfill = function (history) { return ({
    type: FETCH_NOTIFICATION_HISTORY_FULFILL,
    history: history,
}); };
export var fetchNotificationHistoryReject = function (error) { return ({
    type: FETCH_NOTIFICATION_HISTORY_REJECT,
    error: error,
}); };
export var FETCH_NOTIFICATION_READ_DISPATCH = 'WIS/notifications/FETCH_NOTIFICATION_READ_DISPATCHED';
export var FETCH_NOTIFICATION_READ_FULFILL = 'WIS/notifications/FETCH_NOTIFICATION_READ_FULFILLED';
export var FETCH_NOTIFICATION_READ_REJECT = 'WIS/notifications/FETCH_NOTIFICATION_READ_REJECTED';
export var fetchNotificationRead = function () { return ({
    type: FETCH_NOTIFICATION_READ_DISPATCH,
}); };
export var fetchNotificationReadFulfill = function (_a) {
    var count = _a.count, unreadCount = _a.unreadCount;
    return ({
        type: FETCH_NOTIFICATION_READ_FULFILL,
        count: count,
        unreadCount: unreadCount,
        pageNumber: 1,
        pageSize: 10,
        nextPage: 2
    });
};
export var fetchNotificationReadReject = function (error) { return ({
    type: FETCH_NOTIFICATION_READ_REJECT,
    error: error,
}); };
export var UPDATE_NOTIFICATION_READ_DISPATCH = 'WIS/notifications/UPDATE_NOTIFICATION_READ_DISPATCHED';
export var UPDATE_NOTIFICATION_READ_FULFILL = 'WIS/notifications/UPDATE_NOTIFICATION_READ_FULFILLED';
export var UPDATE_NOTIFICATION_READ_REJECT = 'WIS/notifications/UPDATE_NOTIFICATION_READ_REJECTED';
export var updateNotificationRead = function (notificationIds) { return ({
    type: UPDATE_NOTIFICATION_READ_DISPATCH,
    notificationIds: notificationIds,
}); };
export var updateNotificationReadFulfill = function (_a) {
    var count = _a.count, unreadCount = _a.unreadCount;
    return ({
        type: UPDATE_NOTIFICATION_READ_FULFILL,
        count: count,
        unreadCount: unreadCount
    });
};
export var updateNotificationReadReject = function (error) { return ({
    type: UPDATE_NOTIFICATION_READ_REJECT,
    error: error,
}); };
export var FETCH_NOTIFICATION_PREFERENCES_DISPATCH = 'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_DISPATCHED';
export var FETCH_NOTIFICATION_PREFERENCES_FULFILL = 'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_FULFILLED';
export var FETCH_NOTIFICATION_PREFERENCES_REJECT = 'WIS/notifications/FETCH_NOTIFICATION_PREFERENCES_REJECTED';
export var fetchNotificationPreferences = function () { return ({
    type: FETCH_NOTIFICATION_PREFERENCES_DISPATCH,
}); };
export var fetchNotificationPreferencesFulfill = function (preferences) { return ({
    type: FETCH_NOTIFICATION_PREFERENCES_FULFILL,
    preferences: preferences,
}); };
export var fetchNotificationPreferencesReject = function (error) { return ({
    type: FETCH_NOTIFICATION_PREFERENCES_REJECT,
    error: error,
}); };
export var UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED = 'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED';
export var UPDATE_NOTIFICATION_PREFERENCES_FULFILLED = 'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_FULFILLED';
export var UPDATE_NOTIFICATION_PREFERENCES_REJECTED = 'WIS/notifications/UPDATE_NOTIFICATION_PREFERENCES_REJECTED';
export var updateNotificationPreferencesDispatch = function (preferences) { return ({ type: UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED, preferences: preferences }); };
export var updateNotificationPreferencesFulfill = function (preferences) { return ({ type: UPDATE_NOTIFICATION_PREFERENCES_FULFILLED, preferences: preferences }); };
export var updateNotificationPreferencesReject = function (error) { return ({ type: UPDATE_NOTIFICATION_PREFERENCES_REJECTED, error: error }); };
var initialState = {
    history: {
        count: 0,
        unreadCount: 0,
        notifications: [],
        pageNumber: 1,
        pageSize: 10,
        nextPage: 2,
        isFetching: false,
        isFetched: false,
        error: null,
        readError: null,
    },
    preferences: {
        notificationMethod: null,
        isFetching: false,
        isFetched: false,
        error: null,
    },
};
export default function reducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FETCH_NOTIFICATION_READ_FULFILL:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { count: action.count, unreadCount: action.unreadCount, readError: null }) });
        case FETCH_NOTIFICATION_READ_REJECT:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { readError: action.error }) });
        case UPDATE_NOTIFICATION_READ_FULFILL:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { count: action.count, unreadCount: action.unreadCount, pageNumber: action.history.pageNumber, pageSize: action.history.pageSize, nextPage: action.history.nextPage, readError: null }) });
        case UPDATE_NOTIFICATION_READ_REJECT:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { readError: action.error }) });
        case FETCH_NOTIFICATION_HISTORY_DISPATCH:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { pageNumber: 1, pageSize: 10, nextPage: 2, notifications: [], isFetching: true, error: null }) });
        case FETCH_NOTIFICATION_HISTORY_PAGINATION_DISPATCH:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { isFetching: true, error: null }) });
        case FETCH_NOTIFICATION_HISTORY_FULFILL:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { pageNumber: action.pageNumber, notifications: [] }) });
        case FETCH_NOTIFICATION_HISTORY_FULFILL:
            // initial fetch
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { notifications: (_a = action === null || action === void 0 ? void 0 : action.history) === null || _a === void 0 ? void 0 : _a.results, isFetching: false, pageNumber: action.history.pageNumber, pageSize: action.history.pageSize, nextPage: action.history.nextPage, isFetched: true }) });
        case FETCH_NOTIFICATION_HISTORY_REJECT:
            return __assign(__assign({}, state), { history: __assign(__assign({}, state.history), { isFetching: false, isFetched: false, error: action.error }) });
        case FETCH_NOTIFICATION_PREFERENCES_DISPATCH:
            return __assign(__assign({}, state), { preferences: __assign(__assign({}, state.preferences), { isFetching: true, error: null }) });
        case FETCH_NOTIFICATION_PREFERENCES_FULFILL:
            return __assign(__assign({}, state), { preferences: __assign(__assign(__assign({}, state.preferences), action.preferences), { isFetching: false, isFetched: true }) });
        case FETCH_NOTIFICATION_PREFERENCES_REJECT:
            return __assign(__assign({}, state), { preferences: __assign(__assign({}, state.preferences), { isFetching: false, isFetched: false, error: action.error }) });
        case UPDATE_NOTIFICATION_PREFERENCES_DISPATCHED:
            return __assign(__assign({}, state), { preferences: __assign(__assign({}, state.preferences), { isUpdating: true, error: null }) });
        case UPDATE_NOTIFICATION_PREFERENCES_FULFILLED:
            return __assign(__assign({}, state), { preferences: __assign(__assign(__assign({}, state.preferences), action.preferences), { isUpdating: false }) });
        case UPDATE_NOTIFICATION_PREFERENCES_REJECTED:
            return __assign(__assign({}, state), { preferences: __assign(__assign({}, state.preferences), { isUpdating: false, error: action.error }) });
        default:
            return state;
    }
}
