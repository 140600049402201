var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import { renderSelectField, renderTextField, } from '../../util/formik-custom-fields';
import ActionButtonGroup from '../../components/ActionButtonGroup/ActionButtonGroup';
import FieldLabel from '../../components/FieldLabel/FieldLabel';
import Loader from '../../components/Loader/Loader';
import UIBlockListItem from '../../components/UIBlockListItem/UIBlockListItem';
import api from '../../util/api';
import s from '../FlagForm/FlagForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useTaxonomyFlagReasons } from '../../util/APIDjango';
// const mapStateToProps = ({ taxonomyFlagReasons }: RootState) => ({
//   flagReasonTerms: taxonomyFlagReasons.terms,
// });
/**
 * Form to flag messages
 * @param {FlagMessageFormProps} props
 * @returns {JSX.Element}
 */
var FlagMessageForm = function (_a) {
    var id = _a.id, chatId = _a.chatId, author = _a.author, handleCancel = _a.handleCancel;
    var t = useTranslation().t;
    // const { flagReasonTerms } = useSelector(mapStateToProps);
    var _b = useTaxonomyFlagReasons(), flagReasons = _b[0], setFlagReasons = _b[1];
    var flagReasonItems = {};
    flagReasons.terms.forEach(function (term) {
        flagReasonItems[term.id] = term.name;
    });
    return (_jsx(Formik, __assign({ initialValues: {
            id: id,
            chatId: chatId,
            author: author === null || author === void 0 ? void 0 : author.id,
            reason: '',
            reasonDescription: '',
        }, validationSchema: yup.object().shape({
            reason: yup.number().required(t('required.label')),
            reasonDescription: yup.string(),
        }), initialStatus: {
            formError: null,
            flagSubmitted: false,
        }, onSubmit: function (values, _a) {
            var setSubmitting = _a.setSubmitting, setStatus = _a.setStatus;
            setSubmitting(true);
            setStatus({ formError: null, flagSubmitted: false });
            api
                .flagChatMessage(values.chatId, values.id, Number.parseInt(values.reason), values.reasonDescription)
                .then(function (resp) {
                console.log('resp', resp);
                setStatus({ formError: null, flagSubmitted: true });
            })
                .catch(function (err) {
                console.log('err', err);
                var errMsg = err.message;
                if (err.status === 409)
                    errMsg = t('cannotFlagMoreThanOnce.label');
                setStatus({ formError: errMsg, flagSubmitted: false });
            })
                .finally(function () { return setSubmitting(false); });
        } }, { children: function (_a) {
            var values = _a.values, handleChange = _a.handleChange, handleBlur = _a.handleBlur, handleSubmit = _a.handleSubmit, isSubmitting = _a.isSubmitting, status = _a.status;
            return (_jsxs("form", __assign({ className: s.form, onSubmit: handleSubmit }, { children: [isSubmitting ? (_jsx(Loader, { label: t('submittingFlagRequest.label') })) : (_jsxs("div", __assign({ className: s.container }, { children: [status && status.formError ? (_jsxs(_Fragment, { children: [status.formError, _jsx("br", {})] })) : null, status && status.flagSubmitted ? (_jsx("div", __assign({ className: s.flagFulfill }, { children: t('thankYouYourFlagWasSubmitted.label') }))) : (_jsxs(_Fragment, { children: [_jsx(Field, { component: renderSelectField, numVal: true, label: t('pleaseChooseAReasonForFlagging.label'), name: "reason", disabled: isSubmitting, value: values === null || values === void 0 ? void 0 : values.reason, required: true, items: flagReasonItems }), _jsx(Field, { component: renderTextField, type: "text", multiLine: true, name: "reasonDescription", label: t('additionalDetail.label'), disabled: isSubmitting, onChange: handleChange, onBlur: handleBlur, value: values === null || values === void 0 ? void 0 : values.reasonDescription })] })), _jsx(FieldLabel, { label: t('blockAllContentFromAuthor.label') })] }))), !isSubmitting && (author === null || author === void 0 ? void 0 : author.id) && (_jsx(UIBlockListItem, { className: s.blockListItem, id: author === null || author === void 0 ? void 0 : author.username, author: author })), _jsx(ActionButtonGroup, { children: status && status.flagSubmitted ? (_jsx("button", __assign({ type: "button", onClick: handleCancel }, { children: "Done" }))) : (_jsxs(_Fragment, { children: [_jsx("button", __assign({ type: "button", onClick: handleCancel }, { children: t('cancel.label') })), _jsx("button", __assign({ type: "submit" }, { children: t('flagContent.label') }))] })) })] })));
        } })));
};
export default FlagMessageForm;
