var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from './UIContainer.module.scss';
;
var UIContainer = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.bottomSpace, bottomSpace = _b === void 0 ? false : _b;
    return (_jsx("div", __assign({ className: [
            styles.wrapper,
            bottomSpace && styles.bottomSpace,
            className,
        ].join(' ') }, { children: children })));
};
export default UIContainer;
