var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertToRaw } from 'draft-js';
import { useCallback, useMemo, useRef, useState } from 'react';
import createMentionPlugin from '@draft-js-plugins/mention';
import Editor from '@draft-js-plugins/editor';
import MentionsEntry from './MentionsEntry';
import api from '../../util/api';
import editorStyles from './MentionsEditorChat.module.scss';
import mentionsStyles from './MentionsStylesChat.module.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decodeEntity } from 'html-entities';
import CommentMention from '../CommentMention/CommentMention';
export var editorStateToString = function (state) {
    var contentState = state.getCurrentContent();
    var raw = convertToRaw(contentState);
    return raw.blocks
        .map(function (block) {
        var text = block.text, entityRanges = block.entityRanges;
        var tmp = text;
        entityRanges.reverse().forEach(function (_a) {
            var offset = _a.offset, length = _a.length, key = _a.key;
            var entity = raw.entityMap[key];
            if (entity.type === 'mention') {
                tmp =
                    tmp.slice(0, offset) +
                        "{{".concat(entity.data.mention.id, "}}") +
                        tmp.slice(offset + length);
            }
        });
        return tmp;
    })
        .join('\n');
};
var MentionsEditorChat = function (_a) {
    var disabled = _a.disabled, placeholder = _a.placeholder, editorState = _a.editorState, setEditorState = _a.setEditorState, replyMessage = _a.replyMessage, setReplyMessage = _a.setReplyMessage;
    var ref = useRef(null);
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState([]), suggestions = _c[0], setSuggestions = _c[1];
    var t = useTranslation().t;
    var _d = useMemo(function () {
        var mentionPlugin = createMentionPlugin({
            theme: mentionsStyles,
            mentionPrefix: '@',
        });
        var MentionSuggestions = mentionPlugin.MentionSuggestions;
        var plugins = [mentionPlugin];
        return { plugins: plugins, MentionSuggestions: MentionSuggestions };
    }, []), MentionSuggestions = _d.MentionSuggestions, plugins = _d.plugins;
    var onOpenChange = useCallback(setOpen, []);
    var onSearchChange = useCallback(function (_a) {
        var value = _a.value;
        var alreadyMentioned = [];
        var content = ref.current.getEditorState().getCurrentContent();
        content.getBlocksAsArray().forEach(function (block) {
            block.findEntityRanges(function (value) {
                var entityKey = value.getEntity();
                if (!entityKey)
                    return false;
                var entity = content.getEntity(entityKey);
                if (entity.getType() === 'mention') {
                    alreadyMentioned.push(entity.getData().mention.name);
                    return true;
                }
                return false;
            }, function (start, end) { });
        });
        if (value.length <= 1000) {
            value !== '' &&
                api
                    .getMentionableUsersForChat(value, alreadyMentioned)
                    .then(function (users) {
                    setSuggestions(users);
                });
        }
    }, []);
    var decodeCommentText = function (chat) {
        var matches = [];
        var match = [''];
        var restOfText = chat;
        while (match !== null) {
            restOfText = restOfText.substring(match.index + match[0].length);
            match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
            if (match !== null) {
                matches.push({
                    entity: match[0],
                    index: chat.length - restOfText.length + match.index,
                });
            }
        }
        var returnString = chat;
        matches.forEach(function (elem) {
            returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
        });
        return returnString;
    };
    var replyMentions = ((replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.mentions) && replyMessage.mentions.length === 0)
        ? undefined
        : replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.mentions;
    var contentReplyMessage = replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.content;
    var buildReplyComment = function (chatText) {
        if (!replyMentions)
            return [_jsx(_Fragment, { children: chatText })];
        // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
        var mentionEndingIdentifier = "}}";
        var mentionStartingIdentifier = "{{";
        var tmp = chatText.split(mentionStartingIdentifier);
        var messageParts = tmp.map(function (part) {
            if (part.includes(mentionEndingIdentifier)) {
                var splitLine_1 = part.split(mentionEndingIdentifier);
                var mentionedUser = replyMentions.find(function (user) { return user.id == splitLine_1[0]; });
                return (_jsxs(_Fragment, { children: [_jsx(CommentMention, { className: editorStyles.replyMention, user: mentionedUser }), splitLine_1[1]] }));
            }
            return _jsx(_Fragment, { children: part });
        });
        return messageParts;
    };
    return (_jsxs("div", __assign({ className: editorStyles.editor, style: { maxWidth: '100%' }, onClick: function () {
            ref.current.focus();
        } }, { children: [replyMessage && (_jsxs("div", __assign({ className: editorStyles.replyContainer }, { children: [_jsxs("div", __assign({ className: editorStyles.author }, { children: [_jsxs("div", { children: [t('replyingTo.label'), " ", replyMessage.author.username] }), _jsx("div", __assign({ className: editorStyles.closeIcon, onClick: function () { return setReplyMessage(null); } }, { children: _jsx(FontAwesomeIcon, { icon: ['far', 'xmark-circle'] }) }))] })), _jsxs("p", __assign({ className: editorStyles.content }, { children: __spreadArray([], buildReplyComment(decodeCommentText(contentReplyMessage)), true) }))] }))), _jsx(Editor, { editorKey: 'editor', editorState: editorState, onChange: setEditorState, plugins: plugins, ref: ref, placeholder: disabled ? t('noCommentsAllowed.label') : String(placeholder), spellCheck: true }), _jsxs("div", __assign({ style: {
                    fontFamily: '"Cabin Condensed", Helvetica, Arial, sans-serif',
                    fontSize: '0.875rem',
                } }, { children: [editorState.getCurrentContent().getPlainText('').length, " / 1000", ' ', t('characters.label')] })), _jsx(MentionSuggestions, { open: open, onOpenChange: onOpenChange, suggestions: suggestions, onSearchChange: function (searchValue) {
                    searchValue.value.endsWith(" ") ? setSuggestions([]) : onSearchChange(searchValue);
                }, onAddMention: function () {
                    setSuggestions([]);
                }, entryComponent: MentionsEntry })] })));
};
export default MentionsEditorChat;
