import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import React from 'react';
import TabNav from '../TabNav/TabNav';
import { isNative } from '../../util/isNative';
import styles from './Layout.module.scss';
import trackPrevPath from '../../util/trackPrevPath';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';

const Layout = ({ children, location, className }) => {
  const { pathname } = useLocation();
  trackPrevPath(location.pathname);
  const wrapperClasses = [
    styles.wrapper,
    isNative && styles.native,
    pathname.includes('chat') && styles.wrapperChat,
  ].join(' ');

  const mainClasses = [
    styles.wrapper,
    styles.main,
    pathname.includes('chat') && styles.chat,
    className,
  ].join(' ');

  return (
    <div className={wrapperClasses}>
      {isNative ? (
        <TabNav className={styles.tabNav} />
      ) : (
        <Header className={styles.header} />
      )}
      <main className={mainClasses}>{children}</main>
      <Footer className={styles.footer} />
    </div>
  );
};

export default withRouter(Layout);
