// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Avatar-module-wrapper-SPWLT{height:32px;width:32px;border-radius:50%;overflow:hidden;background-color:rgba(1,1,1,.2)}`, "",{"version":3,"sources":["webpack://./src/components/Avatar/Avatar.module.scss"],"names":[],"mappings":"AAAA,8BACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,eAAA,CACA,+BAAA","sourcesContent":[".wrapper {\n  height: 32px;\n  width: 32px;\n  border-radius: 50%;\n  overflow: hidden;\n  background-color: rgba(1,1,1,0.2)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-Avatar-module-wrapper-SPWLT`
};
export default ___CSS_LOADER_EXPORT___;
