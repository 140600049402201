var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styles from './Button.module.scss';
import { useHistory } from 'react-router-dom';
var Button = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'button' : _b, label = _a.label, _c = _a.to, to = _c === void 0 ? null : _c, _d = _a.link, link = _d === void 0 ? null : _d, _e = _a.fullWidth, fullWidth = _e === void 0 ? false : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.loading, loading = _g === void 0 ? false : _g, _h = _a.center, center = _h === void 0 ? false : _h, _j = _a.secondary, secondary = _j === void 0 ? false : _j, _k = _a.largerButton, largerButton = _k === void 0 ? false : _k, _l = _a.dinFont, dinFont = _l === void 0 ? false : _l, _m = _a.nexaFont, nexaFont = _m === void 0 ? false : _m, _o = _a.inlineButtons, inlineButtons = _o === void 0 ? false : _o, _p = _a.onClick, onClick = _p === void 0 ? null : _p;
    var history = useHistory();
    var buttonStyles = [
        styles.button,
        fullWidth && styles.fullWidth,
        disabled && styles.disabled,
        loading && styles.loading,
        center && styles.center,
        inlineButtons && styles.inlineButtons,
        secondary && styles.secondary,
        largerButton && styles.largerButton,
        dinFont && styles.dinFont,
        nexaFont && styles.nexaFont,
    ].join(' ');
    var handleClick = function (e) {
        if (to) {
            history.push(to);
        }
        else if (link) {
            window.open(link, '_blank');
        }
        else {
            onClick(e);
        }
    };
    return (_jsx("button", __assign({ className: buttonStyles, type: type, disabled: disabled || loading, onClick: type === 'submit' || disabled || loading ? null : handleClick }, { children: !loading && _jsx("span", __assign({ className: styles.label }, { children: label })) })));
};
export default Button;
