var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withRouter } from 'react-router';
import styles from './ButtonIcon.module.scss';
var ButtonIcon = function (_a) {
    var title = _a.title, _b = _a.to, to = _b === void 0 ? null : _b, history = _a.history, _c = _a.icon, icon = _c === void 0 ? null : _c, _d = _a.label, label = _d === void 0 ? null : _d, _e = _a.type, type = _e === void 0 ? 'button' : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.onClick, onClick = _g === void 0 ? null : _g, className = _a.className;
    var wrapperClasses = [
        styles.button,
        disabled && styles.disabled,
        className,
    ].join(' ');
    return (_jsxs("div", __assign({ className: styles.wrap }, { children: [_jsx("button", __assign({ title: title, type: type, className: wrapperClasses, disabled: disabled, onClick: function (e) {
                    if (type === 'button') {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    if (to) {
                        history.push(to);
                    }
                    if (onClick) {
                        onClick(e);
                    }
                } }, { children: icon })), label && _jsx("span", __assign({ className: styles.label }, { children: label }))] })));
};
export default withRouter(ButtonIcon);
