import React from 'react';
import PropTypes from 'prop-types';
import styles from './TruncatedText.module.scss';


const TruncatedText = ({ text, className }) => (
  <div className={[styles.truncate, className].join(' ')}>{text}</div>
);

TruncatedText.propTypes = { text: PropTypes.string.isRequired };

export default TruncatedText;
