import { FEATURE_FLAGS } from '../../util/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import React from 'react';
import appStoreLogo from '../../assets/images/app-store-logo.svg';
import logo from '../../assets/images/logo.png';
import playStoreLogo from '../../assets/images/play-store-logo.png';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

const Footer = ({ className }) => {
  const { t } = useTranslation();

  return (
    <footer className={[styles.wrapper, className].join(' ')}>
      <div className={styles.topContent}>
        <div className={styles.logo}>
          <img src={logo} alt="Invictus Games" />
        </div>
        <div className={styles.moto}>
          {t('moto1.label')}
          <br />
          {t('moto2.label')}
          <br />
          {t('moto3.label')}
        </div>
      </div>
      <div className={styles.middleContent}>
        @WEAREINVICTUS
        <br />
        <a
          href="https://www.facebook.com/InvictusGamesFoundation"
          title="facebook">
          <FontAwesomeIcon icon={['fab', 'facebook']} aria-label="facebook" />
        </a>
        <a href="https://twitter.com/weareinvictus" title="twitter">
          <FontAwesomeIcon icon={['fab', 'twitter']} aria-label="twitter" />
        </a>
        <a
          href="https://www.instagram.com/weareinvictusgames/"
          title="instagram">
          <FontAwesomeIcon icon={['fab', 'instagram']} aria-label="instagram" />
        </a>
        <a href="https://www.youtube.com/user/invictuslondon" title="youtube">
          <FontAwesomeIcon icon={['fab', 'youtube']} aria-label="youtube" />
        </a>
        <br />
        {t('footerFollow.label')}
      </div>
      {FEATURE_FLAGS.storeLinks && (
        <div className={styles.storeLinks}>
          <a
            href="https://apps.apple.com/gb/app/we-are-invictus/id1471377135?mt=8"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className={styles.storeLogo}
              alt={t('downloadApple.label')}
              src={appStoreLogo}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=co.flowmo.wis&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            rel="noopener noreferrer">
            <img
              className={styles.storeLogo}
              alt={t('downloadGoogle.label')}
              src={playStoreLogo}
            />
          </a>
        </div>
      )}
      <div className={styles.languageSelect}>
        <LanguageSelector />
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.bottomLeft}>
          {t('footer1.label')}
          <br />
          {t('footer2.label')}
          <br />
          {t('footer3.label')}
          <br />
          {t('footer4.label')}
        </div>
        <div className={styles.bottomRight}>
          <a
            className={styles.link}
            href="https://invictusgamesfoundation.org/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer">
            {t('privacyNotice.label')}
          </a>
          <br />
          <span
            className={styles.link}
            onClick={() =>
              window.open('EULA.html', '_blank', 'usewkwebview=yes')
            }
            role="link">
            {t('licenceAgreement.label')}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
