var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import { Preferences } from '@capacitor/preferences';
import s from './ChatDisclaimer.module.scss';
import { useTranslation } from 'react-i18next';
/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The chat Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
var ChatDisclaimer = function () {
    var _a = useState(), showDisclaimer = _a[0], setShowDisclaimer = _a[1];
    var t = useTranslation().t;
    useEffect(function () {
        var expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() - 4);
        void Preferences.get({
            key: 'chatDisclaimerShown',
        }).then(function (_a) {
            var value = _a.value;
            if (value === null) {
                setShowDisclaimer(true);
            }
            else if (value !== 'true') {
                setShowDisclaimer(new Date(value).getTime() < expiryDate.getTime());
            }
        });
    }, []);
    return (_jsx(Modal, __assign({ show: showDisclaimer }, { children: _jsxs(Card, __assign({ title: t('chatDisclaimer.label'), className: s.label }, { children: [_jsx("p", __assign({ className: s.bodyText }, { children: t('chatRoomsDisclaimer.label') })), _jsx(ButtonGroup, { children: _jsx(Button, { label: t('participate.label'), largerButton: true, nexaFont: true, onClick: function () {
                            return void Preferences.set({
                                key: 'chatDisclaimerShown',
                                value: new Date().toISOString(),
                            }).then(function () { return setShowDisclaimer(false); });
                        }, fullWidth: true }) })] })) })));
};
export default ChatDisclaimer;
