// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-AutoTranslate-module-main-IdPCb{display:flex;flex-direction:row;align-items:center}.-AutoTranslate-module-main-IdPCb .-AutoTranslate-module-content-vgqJZ{flex:1}@supports(display: grid){.-AutoTranslate-module-main-IdPCb{display:grid;grid-template-columns:1fr auto;grid-template-areas:"content toggle";grid-column-gap:8px}.-AutoTranslate-module-main-IdPCb .-AutoTranslate-module-content-vgqJZ{grid-template-areas:"content"}.-AutoTranslate-module-main-IdPCb .-AutoTranslate-module-toggle-bxwhK{grid-template-areas:"toggle"}}`, "",{"version":3,"sources":["webpack://./src/pages/AutoTranslate.module.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,kCACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,uEACE,MAAA,CAOJ,yBACE,kCACE,YAAA,CACA,8BAAA,CACA,oCAAA,CACA,mBChBU,CDkBV,uEACE,6BAAA,CAGF,sEACE,4BAAA,CAAA","sourcesContent":["@import \"../theme/dimensions\";\n@import \"../theme/colors\";\n\n.main {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  .content {\n    flex: 1;\n  }\n\n  .toggle {\n  }\n}\n\n@supports (display: grid) {\n  .main {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    grid-template-areas: \"content toggle\";\n    grid-column-gap: $gutterSmall;\n\n    .content {\n      grid-template-areas: \"content\";\n    }\n\n    .toggle {\n      grid-template-areas: \"toggle\";\n    }\n  }\n}\n","$nav-height: 64px;\n$nav-tab-height: calc(54px + 4px + env(safe-area-inset-bottom));\n\n$gutterExtraSmall: 4px;\n$gutterMediumSmall: 5px;\n$gutterSmall: 8px;\n$gutter: 16px;\n$gutterLarge: 32px;\n\n$mobile: 480px;\n$tablet: 768px;\n$desktop: 1020px;\n\n$fieldWidth: 600px;\n\n@mixin max-960 {\n  max-width: 960px;\n  margin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `-AutoTranslate-module-main-IdPCb`,
	"content": `-AutoTranslate-module-content-vgqJZ`,
	"toggle": `-AutoTranslate-module-toggle-bxwhK`
};
export default ___CSS_LOADER_EXPORT___;
