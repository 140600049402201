// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-AutoTranslateDisclaimer-module-wrapper-MtwFs{align-items:center}.-AutoTranslateDisclaimer-module-wrapper-MtwFs .-AutoTranslateDisclaimer-module-textbody-NxKYu{padding-right:3.4rem}`, "",{"version":3,"sources":["webpack://./src/components/AutoTranslateDisclaimer/AutoTranslateDisclaimer.module.scss"],"names":[],"mappings":"AAAA,+CACE,kBAAA,CAEA,+FACE,oBAAA","sourcesContent":[".wrapper {\n  align-items: center;\n\n  .textbody {\n    padding-right: 3.4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-AutoTranslateDisclaimer-module-wrapper-MtwFs`,
	"textbody": `-AutoTranslateDisclaimer-module-textbody-NxKYu`
};
export default ___CSS_LOADER_EXPORT___;
