import React from 'react';
import PropTypes from 'prop-types';
import { TransitionMotion, spring } from 'react-motion';
import styles from './HideShow.module.scss';

const HideShow = ({ children, show }) => {
  const willLeave = () => ({ maxHeight: 0 });
  const willEnter = () => ({ maxHeight: 0 });

  return (
    <TransitionMotion
      willLeave={willLeave}
      willEnter={willEnter}
      styles={
        show
          ? [
              {
                key: 'a',
                style: {
                  maxHeight: spring(600, { stiffness: 50, damping: 9 }),
                },
              },
            ]
          : []
      }
    >
      {(items) => {
        if (items.length) {
          const { key, style } = items[0];
          return (
            <div
              key={key}
              className={styles.wrapper}
              style={{ maxHeight: style.maxHeight }}
            >
              {children}
            </div>
          );
        }
        return null;
      }}
    </TransitionMotion>
  );
};

HideShow.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default HideShow;
