var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from 'react';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './TextField.module.scss';
import { useTranslation } from 'react-i18next';
var TextField = function (_a) {
    var value = _a.value, id = _a.id, name = _a.name, _b = _a.type, type = _b === void 0 ? 'text' : _b, _c = _a.multiLine, multiLine = _c === void 0 ? false : _c, inputRef = _a.inputRef, _d = _a.maxLength, maxLength = _d === void 0 ? 524288 : _d, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, _e = _a.required, required = _e === void 0 ? false : _e, label = _a.label, min = _a.min, max = _a.max, errorText = _a.errorText, inputClass = _a.inputClass, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.touched, touched = _g === void 0 ? false : _g;
    var _h = useState(false), focused = _h[0], setFocused = _h[1];
    var _j = useState('auto'), textAreaHeight = _j[0], setTextAreaHeight = _j[1];
    var textAreaEl = useRef(null);
    var t = useTranslation().t;
    var updateHeight = function () {
        if (multiLine) {
            window.setTimeout(function () {
                var _a;
                setTextAreaHeight('auto');
                setTextAreaHeight("".concat(((_a = textAreaEl.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) || 0, "px"));
            }, 0);
        }
    };
    useEffect(function () {
        updateHeight();
    }, []);
    var handleChange = function (e) {
        onChange(e);
        updateHeight();
    };
    var handleFocus = function (e) {
        setFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    };
    var handleBlur = function (e) {
        if (onBlur) {
            onBlur(e);
        }
        setFocused(false);
    };
    var fieldType = function () {
        if (multiLine) {
            return (_jsxs(_Fragment, { children: [_jsx("textarea", { maxLength: 35000, rows: 1, id: id, value: value, name: name, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, ref: textAreaEl, style: { height: textAreaHeight }, className: inputClass, disabled: disabled }), _jsxs("div", __assign({ style: {
                            fontFamily: '"Cabin Condensed", Helvetica, Arial, sans-serif',
                            fontSize: '0.875rem',
                            marginTop: '0.5rem',
                        }, className: styles.charCount }, { children: [value.length, " / 35000 ", t('characters.label')] }))] }));
        }
        return (_jsx("input", { id: id, value: value, name: name, type: type && type, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, ref: inputRef, maxLength: maxLength, className: inputClass, disabled: disabled, min: min, max: max }));
    };
    return (_jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsx(FieldLabel, { label: label, required: required }), _jsx(FieldStatusBlock, __assign({ error: errorText, focused: focused, touched: touched }, { children: fieldType() }))] })));
};
export default TextField;
