/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { map } from 'rxjs/operators';
import { FEATURE_FLAGS } from '../../util/config';
import { MainContext } from '../../context/MainContext';
import { scrollEvent } from '../../util/scrollTrack';
import MenuIcon from '../MenuIcon/MenuIcon';
import logo from '../../assets/images/logo.png';
import styles from './Header.module.scss';

const Header = ({ className }) => {
  const { t } = useTranslation();
  const { menuOpen, setContextData, boards } = useContext(MainContext);
  const [show, setShow] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const { pathname } = useLocation();
  const setMenuOpen = isOpen =>
    setContextData(data => ({ ...data, menuOpen: isOpen }));
  const navigationItems = [
    {
      to: '/all-posts',
      label: t('posts.label'),
    },
    {
      to: '/settings',
      label: t('settings.label'),
    },
  ];

  const headerScrollEvent = scrollEvent.pipe(
    map(() => {
      const topPos =
        typeof window !== 'undefined'
          ? window.document.body.getBoundingClientRect().top
          : 0;
      setScrollPos(topPos);
      return scrollPos > 0 - 500 || topPos > scrollPos;
    }),
  );

  useEffect(() => {
    const subscription = headerScrollEvent.subscribe(shouldShow => {
      setShow(shouldShow);
      if (menuOpen) {
        setMenuOpen(false);
      }
    });
    return () => subscription.unsubscribe();
  });

  if (FEATURE_FLAGS.boards && boards.length > 1) {
    navigationItems.splice(1, 0, {
      to: '/boards',
      label: t('boards.label'),
    });
  }

  if (FEATURE_FLAGS.savedPosts) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      to: '/saved',
      label: t('saved.label'),
    });
  }

  if (FEATURE_FLAGS.notifications) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      to: '/notifications',
      label: t('notifications.label'),
    });
  }
  if (FEATURE_FLAGS.chat) {
    navigationItems.splice(navigationItems.length - 1, 0, {
      to: '/chat',
      label: t('chat.label'),
    });
  }

  const headerClasses = [
    styles.wrapper,
    menuOpen && styles.menuOpen,
    !show && styles.hide,
    pathname.includes('chat') && styles.chat,
    className,
  ].join(' ');

  return (
    <header className={headerClasses}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt={t('IGF.label')} />
        </div>
        <Link to="/" className={styles.title}>{`WIS ${t(
          'noticeboard.label',
        )}`}</Link>
        <MenuIcon onClick={() => setMenuOpen(!menuOpen)} open={menuOpen} />
      </div>
      <ul className={styles.menu}>
        {navigationItems.map(({ to, label }) => (
          <li key={label} onClick={() => setMenuOpen(!menuOpen)}>
            <Link to={to} activeClassName={styles.active}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </header>
  );
};

export default Header;
