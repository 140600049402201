import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import UITitle from '../UITitle/UITitle';
import styles from './DetailTitle.modules.scss';
import { useHistory } from 'react-router-dom';

const DetailTitle = ({ title, backLabel, backTo }) => {
  const history = useHistory();
  const backRef = useRef(null);
  const [titleOffsetWidth, setTitleOffsetWidth] = useState(0);
  const onBack = () => {
    history.push(backTo);
  };

  useEffect(() => {
    const offsetWidth = backRef?.current?.offsetWidth;
    setTitleOffsetWidth(offsetWidth || 0);
  }, [backRef.current]);

  return (
    <div className={styles.wrapper}>
      <div
        ref={backRef}
        className={styles.back}
        onClick={onBack}
        onKeyUp={e => (e.key === 13 ? onBack() : null)}
        role="button"
        tabIndex={0}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        <span>{backLabel}</span>
      </div>
      <UITitle className={styles.title} title={title} />
    </div>
  );
};

DetailTitle.propTypes = {
  title: PropTypes.string.isRequired,
  backLabel: PropTypes.string.isRequired,
  backTo: PropTypes.string.isRequired,
};

export default DetailTitle;
