var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useCallback, useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldStatusBlock from '../FieldStatusBlock/FieldStatusBlock';
import styles from './DropZoneField.module.scss';
var getPlatform = Capacitor.getPlatform;
var platform = getPlatform;
var DropZoneField = function (_a) {
    var acceptedMimeTypes = _a.acceptedMimeTypes, _b = _a.maxUploadSize, maxUploadSize = _b === void 0 ? 10485760 : _b, // 10mb
    _c = _a.icon, // 10mb
    icon = _c === void 0 ? _jsx(FontAwesomeIcon, { icon: ['fas', 'file-upload'] }) : _c, _d = _a.label, label = _d === void 0 ? null : _d, _e = _a.required, required = _e === void 0 ? false : _e, _f = _a.onChange, onChange = _f === void 0 ? null : _f, _g = _a.errorText, errorText = _g === void 0 ? null : _g, _h = _a.focused, focused = _h === void 0 ? false : _h, _j = _a.touched, touched = _j === void 0 ? false : _j, value = _a.value;
    var t = useTranslation().t;
    var _k = useState(null), imageData = _k[0], setImageData = _k[1];
    var _l = useState(null), imageName = _l[0], setImageName = _l[1];
    useEffect(function () {
        if (!value) {
            setImageData(null);
            setImageName(null);
        }
    }, [value]);
    var onDrop = useCallback(function (acceptedFiles, rejectedFiles) {
        if (rejectedFiles.length) {
            // TODO Display error when unsupported files added.
            rejectedFiles.forEach(function (file) {
                // this.errorMessage = `${file.name} ${t('fileTooLarge.label')}`;
                // show error message
            });
            return;
        }
        var reader = new FileReader();
        reader.onload = function (e) { return setImageData(e.target.result); };
        reader.readAsDataURL(acceptedFiles[0]);
        setImageName(acceptedFiles[0].name);
        onChange(acceptedFiles[0]);
    }, []);
    var onFileChange = function (event) {
        var files = event.target.files;
        var reader = new FileReader();
        reader.onload = function (e) { return setImageData(e.target.result); };
        reader.readAsDataURL(files[0]);
        setImageName(files[0].name);
        onChange(files[0]);
    };
    var _m = useDropzone({
        onDrop: onDrop,
        multiple: false,
        accept: acceptedMimeTypes.toString(),
        maxSize: maxUploadSize,
    }), getRootProps = _m.getRootProps, getInputProps = _m.getInputProps;
    var removeImage = function () {
        setImageData(null);
        setImageName(null);
    };
    return (_jsxs(Fragment, { children: [_jsx(FieldLabel, { label: imageName ? "".concat(label, " (").concat(imageName, ")") : label, required: required }), _jsxs(FieldStatusBlock, __assign({ error: errorText, focused: focused, touched: touched }, { children: [platform() === 'ios' ? (_jsx("div", __assign({ className: styles.trigger }, { children: _jsxs("label", __assign({ className: styles.fileThumb, style: imageData ? { backgroundImage: "url(".concat(imageData, ")") } : null }, { children: [_jsx("input", { type: 'file', accept: acceptedMimeTypes.toString(), onChange: onFileChange, className: styles.input }), !imageData && (_jsx(FontAwesomeIcon, { icon: ['fas', 'image'], style: { pointerEvents: 'none' } }))] })) }))) : (_jsxs("div", __assign({}, getRootProps({
                        className: styles.trigger,
                    }), { children: [_jsx("input", __assign({}, getInputProps({}))), _jsx("div", __assign({ className: styles.fileThumb, style: imageData ? { backgroundImage: "url(".concat(imageData, ")") } : null }, { children: !imageData && icon }))] }))), imageData && (_jsx("div", __assign({ className: styles.clearText }, { children: _jsx("a", __assign({ onClick: removeImage }, { children: "Clear" })) })))] }))] }));
};
export default DropZoneField;
