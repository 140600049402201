// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-UIContainer-module-wrapper-oEc7O{width:100%;background-color:#fff;height:fit-content}.-UIContainer-module-wrapper-oEc7O.-UIContainer-module-bottomSpace-LKHkQ{margin-bottom:32px}`, "",{"version":3,"sources":["webpack://./src/components/UIContainer/UIContainer.module.scss","webpack://./src/theme/_colors.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAGA,mCACE,UAAA,CACA,qBCMM,CDLN,kBAAA,CAEA,yEACE,kBEFU","sourcesContent":["@import \"../../theme/colors\";\n@import \"../../theme/dimensions\";\n\n.wrapper {\n  width: 100%;\n  background-color: $white;\n  height: fit-content;\n\n  &.bottomSpace {\n    margin-bottom: $gutterLarge\n  }\n}","$yellow: #fcdb00;\n$yellow-main: #ffd700;\n$yellow-dark: #f9ad00;\n$yellow-message: #FFD900;\n$yellow-reply: #FFF099;\n$red: #f1321e;\n$green-400: #66bb6a;\n$green: #00ff00;\n$orange-500: #ff9800;\n$black: #000000;\n$white-800: #fefefe;\n$white: #ffffff;\n$invictus-white: #fff;\n\n$grey-50: #eceff1;\n$grey-100: #cfd8dc;\n$grey-200: #b0bec5;\n$grey-300: #90a4ae;\n$grey-400: #78909c;\n$grey-500: #607d8b;\n$grey-600: #546e7a;\n$grey-700: #455a64;\n$grey-800: #37474f;\n$grey-900: #263238;\n$grey-reply: #CCCCCC;\n$grey-message: #999999;\n$invictus-2nd-dark-grey: #666;\n$invictus-3rd-dark-grey: #333;\n\n$primary: $yellow;\n$secondary: $grey-600;\n$success: $green-400;\n$warning: $orange-500;\n$danger: $red;","$nav-height: 64px;\n$nav-tab-height: calc(54px + 4px + env(safe-area-inset-bottom));\n\n$gutterExtraSmall: 4px;\n$gutterMediumSmall: 5px;\n$gutterSmall: 8px;\n$gutter: 16px;\n$gutterLarge: 32px;\n\n$mobile: 480px;\n$tablet: 768px;\n$desktop: 1020px;\n\n$fieldWidth: 600px;\n\n@mixin max-960 {\n  max-width: 960px;\n  margin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-UIContainer-module-wrapper-oEc7O`,
	"bottomSpace": `-UIContainer-module-bottomSpace-LKHkQ`
};
export default ___CSS_LOADER_EXPORT___;
