// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FlagForm-module-form-nGh36{width:100%}.-FlagForm-module-form-nGh36 .-FlagForm-module-flagDispatch-kkHC3{padding:16px}.-FlagForm-module-form-nGh36 .-FlagForm-module-container-n4RZQ{padding:16px 16px 0px 16px}.-FlagForm-module-form-nGh36 .-FlagForm-module-container-n4RZQ .-FlagForm-module-flagFulfill-QIbQH{padding:16px 0px}.-FlagForm-module-form-nGh36 .-FlagForm-module-blockListItem-tlqiL{border-bottom:none}`, "",{"version":3,"sources":["webpack://./src/forms/FlagForm/FlagForm.module.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAIA,6BACE,UAAA,CAEA,kEACE,YCFK,CDKP,+DACE,0BAAA,CAEA,mGACE,gBAAA,CAIJ,mEACE,kBAAA","sourcesContent":["@import \"../../theme/colors\";\n@import \"../../theme/dimensions\";\n\n\n.form {\n  width: 100%;\n\n  .flagDispatch {\n    padding: $gutter;\n  }\n\n  .container {\n    padding: $gutter $gutter 0px $gutter;\n\n    .flagFulfill {\n      padding: $gutter 0px;\n    }\n  }\n\n  .blockListItem {\n    border-bottom: none;\n  }\n}\n","$nav-height: 64px;\n$nav-tab-height: calc(54px + 4px + env(safe-area-inset-bottom));\n\n$gutterExtraSmall: 4px;\n$gutterMediumSmall: 5px;\n$gutterSmall: 8px;\n$gutter: 16px;\n$gutterLarge: 32px;\n\n$mobile: 480px;\n$tablet: 768px;\n$desktop: 1020px;\n\n$fieldWidth: 600px;\n\n@mixin max-960 {\n  max-width: 960px;\n  margin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `-FlagForm-module-form-nGh36`,
	"flagDispatch": `-FlagForm-module-flagDispatch-kkHC3`,
	"container": `-FlagForm-module-container-n4RZQ`,
	"flagFulfill": `-FlagForm-module-flagFulfill-QIbQH`,
	"blockListItem": `-FlagForm-module-blockListItem-tlqiL`
};
export default ___CSS_LOADER_EXPORT___;
