import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router';
import { AuthContext } from '../../context/AuthProvider';
import { useAuth } from '../../util/APIDjango';

const PrivateRoute = ({ component, ...rest }) => {
  const [authState, setAuthState] = useAuth();
  const { authenticated } = authState;

  return (
    <Route
      {...rest}
      render={props => (
        authenticated
          ? React.createElement(component, props)
          : (
            <Redirect
              to={{
                pathname: '/welcome',
                state: { from: props.location },
              }}
            />
          )
      )}
    />
  );
};

export default PrivateRoute;
