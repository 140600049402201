var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Avatar from '../Avatar/Avatar';
import { LOCALE } from '../../util/config';
import s from './ChatMessage.module.scss';
import defaultAvatar from '../../assets/images/default-avatar.svg';
// import { RootState } from '../../redux/modules/root';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { AuthContext } from '../../context/AuthProvider';
import { LikedIcon } from '../PostDetail/PostDetail';
import { Preferences } from '@capacitor/preferences';
// import { useMessageLiked } from '../../hooks/messageHooks';
import { decodeEntity } from 'html-entities';
import CommentMention from '../CommentMention/CommentMention';
import { useAuth, useMessages, useProfile } from '../../util/APIDjango';
import api from '../../util/api';
import { useHistory } from 'react-router';
var decodeCommentText = function (chat) {
    var matches = [];
    var match = [''];
    var restOfText = chat;
    if (restOfText !== null) {
        while (match !== null) {
            restOfText = restOfText.substring(match.index + match[0].length);
            match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
            if (match !== null) {
                matches.push({
                    entity: match[0],
                    index: chat.length - restOfText.length + match.index,
                });
            }
        }
    }
    var returnString = chat;
    matches.forEach(function (elem) {
        returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
    });
    return returnString;
};
// const mapStateToProps = ({ blocked }: RootState) => ({
//   blockedUsers: blocked.users,
// });
/**
 * Chat Message component, renders a message in the chat
 * Displays an author's image, username, the message content and when it was sent
 * Displays a like button with a message like counter
 * @param {ChatMessageProps} props
 * @returns {JSX.Element}
 */
var ChatMessage = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var message = _a.message, _o = _a.isBlocked, isBlocked = _o === void 0 ? false : _o, _p = _a.isHidden, isHidden = _p === void 0 ? false : _p, displayQuickView = _a.displayQuickView, translatedMessage = _a.translatedMessage, translationError = _a.translationError, onClick = _a.onClick, originalContent = _a.originalContent, chatId = _a.chatId, likedByMe = _a.likedByMe, setShouldScrollToBottom = _a.setShouldScrollToBottom;
    var _q = useState(true), autoTranslateStatus = _q[0], setAutoTranslateStatus = _q[1];
    var _r = useMessages(), messagesData = _r[0], setMessagesData = _r[1];
    // let { messagesByRoom } = messagesData;
    // let { results } = messagesByRoom;
    useEffect(function () {
        void Preferences.get({ key: 'autoTranslateStatus' }).then(function (result) {
            if (!result.value) {
                setAutoTranslateStatus(false);
            }
        });
    }, []);
    // const { likeMessage, removeLikeMessage, liked, setLiked } = useMessageLiked(chatId, message.id);
    // const [liked, setLiked] = useState(false);
    var _s = useState(false), isLiking = _s[0], setIsLiking = _s[1];
    var _t = useState(), error = _t[0], setError = _t[1];
    var isMounted = useRef(true);
    useEffect(function () {
        return function () {
            isMounted.current = false;
        };
    }, []);
    var removeLikeCount = function () {
        var index = messagesData.messagesByRoom.likedByMe.indexOf(message.id);
        if (index !== -1) {
            messagesData.messagesByRoom.likedByMe.splice(index, 1);
        }
        messagesData.messagesByRoom = __assign(__assign({}, messagesData.messagesByRoom), { results: messagesData.messagesByRoom.results.map(function (msg) {
                if (msg.id === message.id) {
                    return __assign(__assign({}, msg), { likesCount: msg.likesCount - 1 });
                }
                return msg;
            }) });
        setMessagesData(__assign(__assign({}, messagesData), { isFetched: true, isFetching: false, messagesByRoom: messagesData.messagesByRoom }));
        setShouldScrollToBottom(false);
    };
    var removeLikeMessage = function () {
        setIsLiking(true);
        return api
            .removeLikeChatMessage(chatId, message.id)
            .then(function () {
            removeLikeCount();
            // if (isMounted.current) 
            // setLiked(false);
        })
            .catch(function (e) {
            console.error(e.message);
            if (isMounted.current)
                setError(e.message);
        })
            .finally(function () {
            if (isMounted.current)
                setIsLiking(false);
        });
    };
    var addLikeCount = function () {
        messagesData.messagesByRoom.likedByMe.push(message.id);
        messagesData.messagesByRoom = __assign(__assign({}, messagesData.messagesByRoom), { results: messagesData.messagesByRoom.results.map(function (msg) {
                if (msg.id === message.id) {
                    return __assign(__assign({}, msg), { likesCount: msg.likesCount + 1 });
                }
                return msg;
            }) });
        setMessagesData(__assign(__assign({}, messagesData), { isFetched: true, isFetching: false, messagesByRoom: messagesData.messagesByRoom }));
        setShouldScrollToBottom(false);
    };
    var likeMessage = function () {
        setIsLiking(true);
        return api
            .likeChatMessage(chatId, message.id)
            .then(function () {
            addLikeCount();
            // if (isMounted.current) setLiked(true);
        })
            .catch(function (e) {
            console.error(e.message);
            if (isMounted.current)
                setError(e.message);
        })
            .finally(function () {
            if (isMounted.current)
                setIsLiking(false);
        });
    };
    var _u = useProfile(), profileState = _u[0], setProfileData = _u[1];
    var t = useTranslation().t;
    // const { uid, user }: { uid: number; user: Profile } = useContext(AuthContext);
    var _v = useAuth(), authState = _v[0], setAuthState = _v[1];
    var uid = authState.uid;
    var user = profileState.profile;
    var history = useHistory();
    var username = isBlocked ? 'Blocked' : (_b = message === null || message === void 0 ? void 0 : message.author) === null || _b === void 0 ? void 0 : _b.username;
    var avatarSrc = isBlocked ? defaultAvatar : defaultAvatar; // message?.author?.avatar?.src;
    var created = isBlocked ? undefined : new Date(message.created);
    // const [likeButtonLabel, setLabel] = useState<string>();
    // const [likeButtonLabel, setLabel] = useState<string>(String(message?.likesCount));
    // const [isLiked, setIsLiked] = useState<boolean>(false);
    // const [likedByMeState, setLikedByMeState] = useState(false);
    var isModerator = (_c = user === null || user === void 0 ? void 0 : user.permissions) === null || _c === void 0 ? void 0 : _c.includes('chat.hide_message');
    var mentions = isBlocked
        || (message.mentions && ((_d = message.mentions) === null || _d === void 0 ? void 0 : _d.length) === 0) ? undefined : message.mentions;
    var replyMentions = isBlocked
        || (((_e = message.repliedToMessage) === null || _e === void 0 ? void 0 : _e.mentions) && message.repliedToMessage.mentions.length === 0)
        ? undefined :
        (_f = message === null || message === void 0 ? void 0 : message.repliedToMessage) === null || _f === void 0 ? void 0 : _f.mentions;
    var toggleLiked = function () {
        history.push("/chat/".concat(chatId));
        setShouldScrollToBottom(false);
        // if (messagesData.messagesByRoom.likedByMe.includes(message.id)) {
        //   setLabel(String(Number(likeButtonLabel) - 1))
        // } else {
        //   setLabel(String(Number(likeButtonLabel) + 1))
        // }
        messagesData.messagesByRoom.likedByMe.includes(message.id) ? removeLikeMessage() : likeMessage();
        // setLiked(prev => !prev);
    };
    var contentMessage = isBlocked
        ? isModerator
            ? t('blockedUser.label')
            : t('blockedMessage.label')
        : isHidden
            ? displayQuickView
                ? originalContent
                : t('moderatorHiddenMessage.label')
            : uid !== (message === null || message === void 0 ? void 0 : message.author.id) && autoTranslateStatus && (message === null || message === void 0 ? void 0 : message.translatedContent)
                ? message === null || message === void 0 ? void 0 : message.translatedContent
                : message === null || message === void 0 ? void 0 : message.content;
    var contentReplyMessage = isBlocked
        ? isModerator
            ? t('blockedUser.label')
            : t('blockedMessage.label')
        : ((_g = message === null || message === void 0 ? void 0 : message.repliedToMessage) === null || _g === void 0 ? void 0 : _g.isHidden)
            ? displayQuickView
                ? originalContent
                : t('moderatorHiddenMessage.label')
            : uid !== (message === null || message === void 0 ? void 0 : message.author.id) &&
                autoTranslateStatus &&
                ((_h = message === null || message === void 0 ? void 0 : message.repliedToMessage) === null || _h === void 0 ? void 0 : _h.translatedContent)
                ? (_j = message === null || message === void 0 ? void 0 : message.repliedToMessage) === null || _j === void 0 ? void 0 : _j.translatedContent
                : (_k = message === null || message === void 0 ? void 0 : message.repliedToMessage) === null || _k === void 0 ? void 0 : _k.content;
    var buildComment = function (chatText) {
        if (!mentions)
            return [_jsx(_Fragment, { children: chatText })];
        // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
        var mentionEndingIdentifier = "}}";
        var mentionStartingIdentifier = "{{";
        var tmp = chatText.split(mentionStartingIdentifier);
        var messageParts = tmp.map(function (part) {
            if (part.includes(mentionEndingIdentifier)) {
                var splitLine_1 = part.split(mentionEndingIdentifier);
                var mentionedUser = mentions.find(function (user) { return user.id == splitLine_1[0]; });
                return (_jsxs(_Fragment, { children: [_jsx(CommentMention, { className: s.mention, user: mentionedUser }), splitLine_1[1]] }));
            }
            return _jsx(_Fragment, { children: part });
        });
        return messageParts;
    };
    var buildReplyComment = function (chatText) {
        if (!replyMentions)
            return [_jsx(_Fragment, { children: chatText })];
        // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
        var mentionEndingIdentifier = "}}";
        var mentionStartingIdentifier = "{{";
        var tmp = chatText.split(mentionStartingIdentifier);
        var messageParts = tmp.map(function (part) {
            if (part.includes(mentionEndingIdentifier)) {
                var splitLine_2 = part.split(mentionEndingIdentifier);
                var mentionedUser = replyMentions.find(function (user) { return user.id == splitLine_2[0]; });
                return (_jsxs(_Fragment, { children: [_jsx(CommentMention, { className: s.mention, user: mentionedUser }), splitLine_2[1]] }));
            }
            return _jsx(_Fragment, { children: part });
        });
        return messageParts;
    };
    // useEffect(() => {
    //   // const likesCount = message.likesCount || 0;
    //   // if (initiallyLiked.current && isLiked) {
    //   //   setLabel(String(likesCount));
    //   // } else if (initiallyLiked.current && !isLiked) {
    //   //   setLabel(String(likesCount - 1));
    //   // } else if (!initiallyLiked.current && isLiked) {
    //   //   setLabel(String(likesCount + 1));
    //   // } else if (!initiallyLiked.current && !isLiked) {
    //   //   setLabel(String(likesCount ? message.likesCount : 0));
    //   // }
    //   const userLiked = likedByMe.includes(message?.id);
    //   setLikedByMeState(userLiked);
    //   setLiked(userLiked);
    //   setLabel(String(message?.likesCount));
    // }, [liked, message.likesCount]);
    return (_jsxs("div", __assign({ id: message.id.toFixed(0), className: [
            s.wrapper,
            uid == message.author.id ? s.fromUser : undefined,
            isBlocked ? s.blocked : undefined,
            isHidden ? s.hidden : undefined,
        ].join(' ') }, { children: [_jsx(Avatar, { className: s.avatar, src: ((_m = (_l = message === null || message === void 0 ? void 0 : message.author) === null || _l === void 0 ? void 0 : _l.avatar) === null || _m === void 0 ? void 0 : _m.src) ? message.author.avatar.src : avatarSrc, alt: "".concat(username, " Avatar") }), _jsxs("div", __assign({ className: "".concat(s.body, " ").concat(displayQuickView ? s.quickView : ''), onClick: onClick }, { children: [message.repliedToMessage && (_jsxs("div", __assign({ className: [
                            s.replyContainer,
                            uid == message.author.id ? s.fromReply : s.toReply,
                        ].join(' ') }, { children: [(!message.repliedToMessage.isHidden || displayQuickView) && (_jsxs("div", __assign({ className: s.author }, { children: [t('replyingTo.label'), " ", message.repliedToMessage.author.username] }))), _jsxs("p", __assign({ className: s.content }, { children: __spreadArray([], buildReplyComment(decodeCommentText(contentReplyMessage)), true) }))] }))), (!isHidden || displayQuickView) && (_jsx("div", __assign({ className: s.author }, { children: username }))), _jsxs("p", __assign({ className: s.content }, { children: __spreadArray([], buildComment(decodeCommentText(contentMessage)), true) })), ((!isHidden || displayQuickView) && !isBlocked) && (_jsxs("div", { children: [_jsx("div", __assign({ className: s.likeNew }, { children: _jsx(ButtonIcon, { disabled: isLiking, title: messagesData.messagesByRoom.likedByMe.includes(message.id) ? "unlike" : "like", onClick: function () { return toggleLiked(); }, label: isBlocked ? '' : String(messagesData.messagesByRoom.results.find(function (value) { return value.id === message.id; }).likesCount), className: s.iconNew, icon: isBlocked ? _jsx(_Fragment, {}) :
                                        messagesData.messagesByRoom.likedByMe.includes(message.id) ? (_jsx(LikedIcon, {})) : (_jsx(FontAwesomeIcon, { icon: ['far', 'heart'] })) }) })), _jsxs("div", __assign({ className: s.metaWrapper }, { children: [_jsx("p", __assign({ className: s.time }, { children: created &&
                                            "".concat(created.toLocaleDateString(LOCALE), ", ").concat(created.toLocaleTimeString(LOCALE, {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })) })), translationError && (_jsx("div", __assign({ className: s.translationError }, { children: translationError }))), !translationError && translatedMessage && (_jsx("div", __assign({ className: s.translatedMessage }, { children: translatedMessage })))] }))] }))] }))] })));
};
export default ChatMessage;
