import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './MenuIcon.module.scss';

const MenuIcon = ({ onClick, open, className }) => {
  const { t } = useTranslation();
  return (
    <button
      className={[
        styles.wrapper,
        open && styles.open,
        className,
      ].join(' ')}
      title={t('menuToggle.label')}
      type="button"
      tabIndex="0"
      aria-pressed={open}
      onClick={onClick}
    >
      <span />
      <span />
      <span />
      <span />
    </button>
  );
};

MenuIcon.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

MenuIcon.defaultProps = {
  open: false,
  onClick: null,
};

export default MenuIcon;
