import * as yup from 'yup';

import { Field, Formik } from 'formik';
import React, { Fragment } from 'react';
import {
  renderSelectField,
  renderTextField,
} from '../../util/formik-custom-fields';
import { useDispatch, useSelector } from 'react-redux';

import ActionButtonGroup from '../../components/ActionButtonGroup/ActionButtonGroup';
import FieldLabel from '../../components/FieldLabel/FieldLabel';
import Loader from '../../components/Loader/Loader';
import PropTypes from 'prop-types';
import UIBlockListItem from '../../components/UIBlockListItem/UIBlockListItem';
import api from '../../util/api';
import { authorType } from '../../proptypes/author';
import styles from './FlagForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useFlags, useTaxonomyFlagReasons } from '../../util/APIDjango';

// const mapStateToProps = ({ taxonomyFlagReasons }) => ({
//   flagReasonTerms: taxonomyFlagReasons.terms,
// });

const FlagForm = ({ id, type, author, handleCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { flagReasonTerms } = useSelector(mapStateToProps);

  const [flagReasons, setFlagReasons] = useTaxonomyFlagReasons();
  const [flags, setFlags] = useFlags();

  const flagReasonItems = {};
  flagReasons.terms.forEach(term => {
    flagReasonItems[term.id] = term.name;
  });

  const flagSubmitDispatch = () => {
    setFlags({
      ...flags,
      flagging: true,
      flagged: false,
      error: null,
    })
  }

  return (
    <Formik
      initialValues={{
        id,
        type,
        author: author?.id,
        reason: '',
        reasonDescription: '',
      }}
      validationSchema={yup.object().shape({
        reason: yup.number().required(t('required.label')),
        reasonDescription: yup.string(),
      })}
      initialStatus={{
        formError: null,
        flagSubmited: false,
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        // dispatch(flagDispatch(values?.id));
        flagSubmitDispatch();
        setStatus({ formError: null, flagSubmited: false });
        api
          .flagContent(values)
          .then(() => {
            setSubmitting(false);
            setStatus({ formError: null, flagSubmited: true });
          })
          .catch(err => setStatus({ formError: err, flagSubmited: false }));
      }}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        status,
      }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          {isSubmitting ? (
            <Loader label={t('submittingFlagRequest.label')} />
          ) : (
            <div className={styles.container}>
              {status && status.formError ? (
                <Fragment>
                  {status.formError}
                  <br />
                </Fragment>
              ) : null}
              {status && status.flagSubmited ? (
                <div className={styles.flagFulfill}>
                  {t('thankYouYourFlagWasSubmitted.label')}
                </div>
              ) : (
                <Fragment>
                  <Field
                    component={renderSelectField}
                    numVal
                    label={t('pleaseChooseAReasonForFlagging.label')}
                    name="reason"
                    disabled={isSubmitting}
                    value={values?.reason}
                    required
                    items={flagReasonItems}
                  />
                  <Field
                    component={renderTextField}
                    type="text"
                    multiLine
                    name="reasonDescription"
                    label={t('additionalDetail.label')}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.reasonDescription}
                  />
                </Fragment>
              )}
              <FieldLabel label={t('blockAllContentFromAuthor.label')} />
            </div>
          )}
          {!isSubmitting && (
            <UIBlockListItem
              className={styles.blockListItem}
              id={author?.username}
              author={author}>
              {author?.username}
            </UIBlockListItem>
          )}
          <ActionButtonGroup>
            {status && status.flagSubmited ? (
              <button type="button" onClick={handleCancel}>
                Done
              </button>
            ) : (
              <Fragment>
                <button
                  style={{ color: '#000' }}
                  type="button"
                  onClick={handleCancel}>
                  {t('cancel.label')}
                </button>
                <button
                  style={{ color: '#000' }}
                  type="submit"
                  disabled={isSubmitting}>
                  {t('flagContent.label')}
                </button>
              </Fragment>
            )}
          </ActionButtonGroup>
        </form>
      )}
    </Formik>
  );
};

FlagForm.propTypes = {
  author: PropTypes.shape(authorType).isRequired,
  handleCancel: PropTypes.func,
};

FlagForm.defaultProps = {
  handleCancel: null,
};

export default FlagForm;
