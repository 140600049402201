// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-FieldLabel-module-wrapper-bx9mm{display:flex;align-items:center;margin-bottom:4px;color:#546e7a}.-FieldLabel-module-wrapper-bx9mm.-FieldLabel-module-light-UPqyW{color:#fff}.-FieldLabel-module-disabled-tvkGl{color:#cfd8dc}`, "",{"version":3,"sources":["webpack://./src/components/FieldLabel/FieldLabel.module.scss","webpack://./src/theme/_colors.scss"],"names":[],"mappings":"AAEA,kCACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,aCcS,CDXX,iEACE,UCCM,CDER,mCACE,aCCS","sourcesContent":["@import \"../../theme/colors.scss\";\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 4px;\n  color: $grey-600;\n}\n\n.wrapper.light {\n  color: $white;\n}\n\n.disabled {\n  color: $grey-100;\n}\n","$yellow: #fcdb00;\n$yellow-main: #ffd700;\n$yellow-dark: #f9ad00;\n$yellow-message: #FFD900;\n$yellow-reply: #FFF099;\n$red: #f1321e;\n$green-400: #66bb6a;\n$green: #00ff00;\n$orange-500: #ff9800;\n$black: #000000;\n$white-800: #fefefe;\n$white: #ffffff;\n$invictus-white: #fff;\n\n$grey-50: #eceff1;\n$grey-100: #cfd8dc;\n$grey-200: #b0bec5;\n$grey-300: #90a4ae;\n$grey-400: #78909c;\n$grey-500: #607d8b;\n$grey-600: #546e7a;\n$grey-700: #455a64;\n$grey-800: #37474f;\n$grey-900: #263238;\n$grey-reply: #CCCCCC;\n$grey-message: #999999;\n$invictus-2nd-dark-grey: #666;\n$invictus-3rd-dark-grey: #333;\n\n$primary: $yellow;\n$secondary: $grey-600;\n$success: $green-400;\n$warning: $orange-500;\n$danger: $red;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-FieldLabel-module-wrapper-bx9mm`,
	"light": `-FieldLabel-module-light-UPqyW`,
	"disabled": `-FieldLabel-module-disabled-tvkGl`
};
export default ___CSS_LOADER_EXPORT___;
