// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-TruncatedText-module-truncate-R4uWt{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/components/TruncatedText/TruncatedText.module.scss"],"names":[],"mappings":"AAAA,sCACE,kBAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":[".truncate {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"truncate": `-TruncatedText-module-truncate-R4uWt`
};
export default ___CSS_LOADER_EXPORT___;
