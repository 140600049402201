// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-CheckboxButtonGroup-module-wrapper-SuS0s{margin-bottom:16px}.-CheckboxButtonGroup-module-wrapper-SuS0s .-CheckboxButtonGroup-module-items-I2ehy{margin:16px -4px 0px}`, "",{"version":3,"sources":["webpack://./src/components/CheckboxButtonGroup/CheckboxButtonGroup.module.scss","webpack://./src/theme/_dimensions.scss"],"names":[],"mappings":"AAEA,2CACE,kBCGO,CDDP,oFACE,oBAAA","sourcesContent":["@import \"../../theme/dimensions\";\n\n.wrapper {\n  margin-bottom: $gutter;\n\n  .items {\n    margin: $gutter -4px 0px;\n  }\n}\n","$nav-height: 64px;\n$nav-tab-height: calc(54px + 4px + env(safe-area-inset-bottom));\n\n$gutterExtraSmall: 4px;\n$gutterMediumSmall: 5px;\n$gutterSmall: 8px;\n$gutter: 16px;\n$gutterLarge: 32px;\n\n$mobile: 480px;\n$tablet: 768px;\n$desktop: 1020px;\n\n$fieldWidth: 600px;\n\n@mixin max-960 {\n  max-width: 960px;\n  margin-left: auto;\n  margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `-CheckboxButtonGroup-module-wrapper-SuS0s`,
	"items": `-CheckboxButtonGroup-module-items-I2ehy`
};
export default ___CSS_LOADER_EXPORT___;
